import {Component, EventEmitter, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {take} from 'rxjs/operators';
import {IMenuItem} from '@nxt/model-core';
import {CommonModule} from '@angular/common';

import {PageService, IModalOptions} from '../_services/page.service';
import {IconsComponent} from '../icons/icons.component';


@Component({
    standalone: true,
    imports: [CommonModule, IconsComponent],
    selector: 'modal-dialog',
    template: `
        <div class="nxt-modal" style="z-index: 9999">
            <div class="nxt-modal-wrapper">
                <div class="nxt-modal-overlay" aria-hidden="true"></div>
                <span class="nxt-modal-centering-trick" aria-hidden="true">&#8203;</span>
                <div class="nxt-modal-content"  [style]="styles">
                    <div class="nxt-modal-header">
                        <button (click)="close()" class="btn-clear text-light hover:text-accent">
                            <span class="sr-only">Close</span>
                            <icon name="heroicon-outline-x" class="h-6 w-6"></icon>
                        </button>
                        <div class="mt-2 text-light font-bold" *ngIf="label">{{ label }}</div>
                    </div>
                    <div #containerElement class="max-h-[calc(85dvh)] overflow-y-auto">
                        <ng-container #targetContainer></ng-container>
                    </div>
                    <div class="nxt-modal-footer" *ngIf="!hideButtons">
                        <button class="btn-gray btn-sm" (click)="close()" *ngIf="!buttons?.length">
                            {{closeLabel}}
                        </button>
                        <ng-container *ngFor="let btn of buttons">
                            <button *ngIf="!btn.show || btn.show()"
                                    [class]="'btn-sm '+(btn.class||'btn-gray')"
                                    (click)="clickBtn(btn)">
                                {{ btn.label }}
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class ModalDialog {
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onLoaded: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('targetContainer', {read: ViewContainerRef, static: true}) targetContainer: ViewContainerRef;
    @ViewChild('containerElement', {read: ViewContainerRef, static: true}) containerElement: ViewContainerRef;
    options: IModalOptions;
    label: string;
    componentRef: any;
    buttons: IMenuItem[];
    closeLabel: string = 'Close';
    styles: any = {};
    hideButtons: boolean;

    constructor(
        public pSvc: PageService
    ) {
    }

    async clickBtn(btn: IMenuItem) {
        if (btn.click) {
            await btn.click();
        }
        if (btn.closeOnClick) {
            this.onClose.emit();
        }
    }

    init(options: IModalOptions) {
        this.options = options;
        if (this.options?.component && this.targetContainer) {
            if (this.componentRef) {
                this.targetContainer.clear();
            }
            this.componentRef = this.targetContainer.createComponent<any>(this.options.component);
            this.closeLabel = this.options.closeLabel || 'Close';
            this.label = this.options.label || '';
            this.componentRef.instance?.onClose?.pipe(take(1))
                .subscribe(e => {
                    this.onClose.emit(e);
                });


            if (this.componentRef.instance.buttons !== undefined) {
                this.hideButtons = true;
            } else {
                this.buttons = options.buttons || [];
            }

            this.styles = options.styles || this.componentRef.instance.styles || this.styles;
            // if (this.componentRef.instance?.save instanceof Function) {
            //     this.pSvc.clickSave$.pipe(take(1)).subscribe(e => {
            //         if (e) {
            //             this.componentRef.instance?.save();
            //         }
            //     })
            // }
            if (this.options.onLoaded) {
                this.options.onLoaded(this.componentRef.instance);
            }
        }
    }

    close() {
        if (this.componentRef?.instance?.close) {
            this.componentRef.instance?.close();
        } else if (this.componentRef?.instance?.onClose) {
            this.componentRef.instance?.onClose.emit();
        }
        this.onClose.emit();
    }
}
