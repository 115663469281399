import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {
    EMedium,
    ThreadMessage,
    EThreadMessageEvents,
    prettifyPhone,
    IMenuItem,
    formatDate, Contact
} from '@nxt/model-core';

import {HtmlViewerDialog} from './html-viewer.dialog';
import {EThreadContext, MessagingService} from '@library/nxt/_services/messaging.service';
import {PageService} from '@library/shared/_services/page.service';
import {ClientService} from '@library/shared/_services/client.service';
import {CommonModule} from '@angular/common';

import {MessageMediumIcon} from '@library/nxt/list/message-medium-icon';
import {AttachmentViewerComponent} from './attachment-viewer.component';

import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {AvatarComponent} from '@library/shared/avatars/avatar.component';
import {MessageItemSummary} from './message-item-summary';
import {FromComponent} from './_subcomponents/from.component';
import {SubjectComponent} from './_subcomponents/subject.component';
import {MessageResultsComponent} from './_subcomponents/results.component';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {MessageReactions} from './message-reactions';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        MessageMediumIcon, AttachmentViewerComponent,
        PopButtonComponent, AvatarComponent,
        MessageItemSummary, FromComponent,
        SubjectComponent, MessageResultsComponent, IconsComponent, MessageReactions
    ],
    selector: 'message-item',
    template: `

        <div *ngIf="message" class="border-b border-gray-100"
             [class]="itemClass"
             [style]="itemStyle"
             (click)="selectIfDraft()">
            <div class="flex flex-col align-top text-center">
                <button class="btn-clear btn-round btn-xs mb-2"
                        (click)="toggleSelection()">
                    <message-medium-icon class="w-4 h-4" [item]="message"></message-medium-icon>
                    <ng-container *ngIf="message.medium === EMedium.EMAIL || message.medium === EMedium.WEB">
                        <icon name="heroicon-outline-chevron-up" *ngIf="selected" class="h-4 w-4 text-dark-400"></icon>
                        <icon name="heroicon-outline-chevron-down" *ngIf="!selected" class="h-4 w-4 text-dark-400"></icon>    
                    </ng-container>
                </button>

                <results [parent]="message"></results>
                <message-reactions [message]="message"></message-reactions>
                
            </div>
            <div class="grow pl-0 align-top overflow-hidden">
                <ng-container *ngTemplateOutlet="selected ? fullEmailHeader : compactHeader; context:{message:message}"></ng-container>

                <div>
                    <subject [parent]="message"></subject>

                    <ng-container *ngIf="message?.files?.length">
                        <attachment-viewer class="break-all" [condensed]="true"
                                           [parent]="message"></attachment-viewer>
                    </ng-container>

                    <ng-container *ngIf="selected || message.medium!==EMedium.EMAIL">
                        <div class="m-0.5 text-sm text-gray-800 align-top overflow-hidden"
                             [innerHTML]="message._message"></div>
                    </ng-container>

                </div>

                <ng-container *ngIf="selected">

                    <div class="mb-2">
                        <div *ngIf="longHtml" (click)="showHtml(message)"
                             class="badge bg-blue-100 text-blue-800 cursor-pointer">
                            Show Full Email
                        </div>
                    </div>

                    <div *ngIf="history?.length" class="ml-5 mt-2 pt-2 border-t border-gray-200">
                        <div *ngFor="let item of history">
                            <ng-container
                                    *ngTemplateOutlet="historyItemTemplate; context:{message:item}"></ng-container>
                        </div>
                    </div>
                </ng-container>
                
            </div>
        </div>

        <ng-template let-message="message" #compactHeader>
            <div class="flex w-full justify-between">
                <from (click)="toggleSelection()" [parent]="message"></from>
                <div class="whitespace-nowrap">
                    <button [disabled]="(message.medium !== EMedium.EMAIL && message.medium !== EMedium.WEB)"
                            class="btn-clear btn-xs" title="Reply" (click)="reply(message)">
                        <icon name="heroicon-solid-chevron-left" class="h-4 w-4 text-blue"></icon>
                    </button>
                    <button [disabled]="(message.medium !== EMedium.EMAIL && message.medium !== EMedium.WEB)"
                            class="btn-clear btn-xs" title="Reply All" (click)="replyAll(message)">
                        <icon name="heroicon-solid-chevron-double-left"
                                class="h-4 w-4 text-blue"></icon>
                    </button>
                    <button [disabled]="(message.medium !== EMedium.EMAIL && message.medium !== EMedium.WEB)"
                            class="btn-clear btn-xs" title="Forward" (click)="forward(message)">
                        <icon name="heroicon-solid-chevron-right" class="h-4 w-4 text-blue"></icon>
                    </button>
                    <pop-button [items]="msgOptions"
                                *ngIf="msgOptions"
                                btnClass="btn-clear btn-xs"
                                iconName="heroicon-solid-dots-horizontal"
                                iconClass="w-4 h-4">
                    </pop-button>
                </div>
            </div>
        </ng-template>

        <ng-template let-message="message" #fullEmailHeader>
            <ng-container *ngTemplateOutlet="compactHeader; context:{message:message}"></ng-container>
            <table class="w-full text-gray-400 text-xs">
                <tr *ngIf="message.recipients?.length">
                    <td class="align-top whitespace-nowrap">
                        to:
                    </td>
                    <td class="align-top w-full">
                        <ng-container *ngFor="let recip of message.recipients">
                            <div class="mb-1" *ngIf="message.medium === EMedium.EMAIL">
                                {{recip.email}}
                            </div>
                            <div class="mb-1" *ngIf="message.medium === EMedium.TEXT">
                                {{ recip._type==='clients' ? recip.name : prettifyPhone(recip.phone)}}
                            </div>
                        </ng-container>
                    </td>
                </tr>
                <tr *ngIf="!message.recipients?.length && message.inbound">
                    <td class="align-top whitespace-nowrap">
                        to:
                    </td>
                    <td class="align-top w-full">
                        {{ (cSvc.client$|async)?.name }}
                    </td>
                </tr>
                <tr *ngIf="message.cc?.length">
                    <td class="align-top whitespace-nowrap">
                        cc:
                    </td>
                    <td class="align-top w-full">
                        <ng-container *ngFor="let recip of message.cc">
                            <div class="mb-1" *ngIf="recip.email !== message.creator?.email">
                                {{recip.email}}
                            </div>
                        </ng-container>
                    </td>
                </tr>
                <tr *ngIf="message.bcc?.length && message.object?._type !== 'groups'">
                    <td class="align-top whitespace-nowrap">
                        bcc:
                    </td>
                    <td class="align-top w-full">
                        <ng-container *ngFor="let recip of message.bcc">
                            <div class="mb-1" *ngIf="recip.email !== message.creator?.email">
                                {{recip.email}}
                            </div>
                        </ng-container>
                    </td>
                </tr>
            </table>


        </ng-template>

        <ng-template let-message="message" #historyItemTemplate>
            <table class="w-full text-gray-400 text-xs">
                <tr>
                    <td class="align-top whitespace-nowrap">
                        from:
                    </td>
                    <td class="align-top w-full">
                        {{ message.creator?.nick_name || message.creator?.email}}
                    </td>
                </tr>
                <tr>
                    <td class="align-top whitespace-nowrap">
                        sent:
                    </td>
                    <td class="align-top w-full">
                        {{ message.date|date:'EEE M/d/yy h:mm a' }}
                    </td>
                </tr>
                <tr *ngIf="message.recipients?.length">
                    <td class="align-top whitespace-nowrap">
                        to:
                    </td>
                    <td class="align-top w-full">
                        <ng-container *ngFor="let recip of message.recipients">
                            <div class="mb-1" *ngIf="message.medium === EMedium.EMAIL">
                                {{recip.email}}
                            </div>
                            <div class="mb-1" *ngIf="message.medium === EMedium.TEXT">
                                {{ recip._type==='clients' ? recip.name : prettifyPhone(recip.phone)}}
                            </div>
                        </ng-container>
                    </td>
                </tr>
                <tr *ngIf="!message.recipients?.length && message.inbound">
                    <td class="align-top whitespace-nowrap">
                        to:
                    </td>
                    <td class="align-top w-full">
                        {{ (cSvc.client$|async)?.name }}
                    </td>
                </tr>
                <tr *ngIf="message.cc?.length">
                    <td class="align-top whitespace-nowrap">
                        cc:
                    </td>
                    <td class="align-top w-full">
                        <ng-container *ngFor="let recip of message.cc">
                            <div class="mb-1" *ngIf="recip.email !== message.creator?.email">
                                {{recip.email}}
                            </div>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td class="align-top whitespace-nowrap">
                        subject:
                    </td>
                    <td class="align-top w-full">
                        {{ message.subject }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="align-top">
                        <div class="m-0.5 text-sm text-gray-800 align-top overflow-hidden"
                             [innerHTML]="message._message"></div>
                        <div *ngIf="message.html?.length > 500" (click)="showHtml(message)"
                             class="badge bg-blue-100 text-blue-800 cursor-pointer">
                            Show Full Email
                        </div>
                    </td>
                </tr>
            </table>
        </ng-template>
    `
})
export class MessageItemComponent implements OnChanges {
    @Output() onSelect: EventEmitter<ThreadMessage> = new EventEmitter<ThreadMessage>();
    @Output() onDelete: EventEmitter<ThreadMessage> = new EventEmitter<ThreadMessage>();
    @Input() index: number;
    @Input() parent: any;
    @Input() message: ThreadMessage
    @Input() selected: boolean;
    EThreadContext = EThreadContext;
    longHtml: boolean;
    EMedium = EMedium;
    prettifyPhone = prettifyPhone;
    msgOptions: IMenuItem[];
    history: ThreadMessage[];
    // replyAllEnabled: boolean;
    itemStyle: string;
    get itemClass(): string {
        if(this.message.medium ===EMedium.CHAT && this.message.inbound===true){
            return this.selected ? 'p-2 bg-red-100 flex' : 'p-2 bg-red-100 flex'
        } else if (this.message.medium === EMedium.NOTE || this.message.medium === EMedium.CHAT) {
            return this.selected ? 'p-2 bg-yellow-100 flex' : 'p-2 bg-yellow-100 flex'
        } else {
            return this.selected ? 'p-2 bg-blue-100 flex' : 'p-2 bg-white flex'
        }
    }

    constructor(
        public cSvc: ClientService,
        public mSvc: MessagingService,
        private pSvc: PageService,
        private sanitizer: DomSanitizer
    ) {
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.message?.currentValue?.id !== changes.message?.previousValue?.id) {
            if (this.message?.html) {
                this.longHtml = (this.message.medium === EMedium.EMAIL || this.message.medium === EMedium.WEB || this.message.medium === EMedium.ARCHIVE);
            }
            this.itemStyle = (this.message?.unread) ? 'border-left: 2px solid red' : '';
            if (!this.message.inbound && this.message.html && !this.message.template_id) {
                this.message._message = this.sanitizer.bypassSecurityTrustHtml(this.message.html);
            } else if (this.message?.message) {
                this.message._message = this.sanitizer.bypassSecurityTrustHtml(this.message.message.replace(/\n/g, '<br/>'));
            } else if (this.message?.summary) {
                this.message._message = this.sanitizer.bypassSecurityTrustHtml(this.message.summary.replace(/\n/g, '<br/>'));
            }

            this.msgOptions = await this.mSvc.buildMsgOptions(this.message);
            await this.message.loadAll({
                loadAllFn: this.mSvc.loadAll,
                olm: this.mSvc.olm
            });
            // this.replyAllEnabled = (((this.message.recipients?.length||0) + (this.message.cc?.length||0)) >= 1);
        }
    }

    async selectIfDraft() {
        if (this.message.draft) {
            this.mSvc.saveMsg(this.mSvc.thread$.getValue().id, this.message);
            this.mSvc.activeMsg$.next(this.message);
        }
    }

    async toggleSelection() {
        console.log(this.message?._docRef?.path);
        if (this.message.medium === EMedium.EMAIL
            || this.message.medium === EMedium.WEB
            || this.message.medium===EMedium.TEXT) {

            if (!this.selected) {
                this.onSelect.emit(this.message);
            } else {
                this.onSelect.emit(null);
            }
        }
        if (this.message._exists && this.message.unread) {
            await this.message.update({unread: false});
        }
    }

    showHtml(msg: ThreadMessage) {
        if (this.cSvc.name_key === 'EQIP') {
            this.mSvc.emitShowFullEmailInEqip(true, msg);
        }else{
            this.pSvc.modal$.next({
                component: HtmlViewerDialog,
                styles: {width: '90%', height: '90%'},
                label: 'Email',
                onLoaded: async (comp: HtmlViewerDialog) => {

                    comp.message = msg;
                    let historyHtml: string = '';
                    if (!this.history && this.message.replyingTo) {
                        this.history = [];

                        let q = await this.message._docRef.parent.parent
                            .collection('threadsmsgs')
                            .orderBy('date', 'desc')
                            .where('date', '<=', this.message.replyingTo)
                            .where('tRef', '==', this.mSvc.thread$.getValue()._docRef)
                            .limit(10).get();

                        if (q.size) {
                            historyHtml += `<table class="es-content" cellspacing="0" cellpadding="0" align="left" style="margin-left:20px; mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;"><tr style="border-collapse:collapse"><td align="left" style="padding:5px;Margin:0">`;

                            this.history = q.docs.reduce(async (r, doc) => {
                                let m: ThreadMessage = new ThreadMessage(doc, this.mSvc.olm);
                                if (m.id !== this.message.id && m.medium === EMedium.EMAIL && r.length < 3) {

                                    let msg: ThreadMessage = new ThreadMessage(doc, this.mSvc.olm);

                                    historyHtml += `<hr/>
                                <i>Date: ${formatDate(m.date)}</i><br/>
                                <i>From: ${m.creator.email}</i><br/>
                                <strong>Subject: ${m.subject}</strong><br/>`

                                    if (!msg.inbound && msg.html && !msg.template_id) {
                                        historyHtml += `<p>${msg.html}</p>`
                                    } else if (msg?.message) {
                                        historyHtml += `<p>${msg.message.replace(/\n/g, '<br/>')}</p>`;
                                    } else if (msg?.summary) {
                                        historyHtml += `<p>${msg.summary.replace(/\n/g, '<br/>')}</p>`;
                                    }
                                    r.push(msg);
                                }
                                return r;
                            }, []);

                            historyHtml += `</td></tr></table>`
                        }
                    }
                    historyHtml += `<hr/><p>NOTE: Attachments and your signature are not displayed here, but are sent with the message.</p><br/><br/><br/>`

                    comp.html = msg.html + historyHtml;
                    comp.ngOnChanges();
                }
            });
        }

    }

    delete() {
        this.pSvc.alert$.next({
            title: 'Are You Sure?',
            message: 'This will delete the email and any attachments. Click Delete to confirm.',
            buttons: [
                {
                    label: 'Delete',
                    closeOnClick: true,
                    class: 'btn-red',
                    click: async () => {
                        this.pSvc.loading$.next(true);
                        try {
                            this.onDelete.emit(this.message);
                            await this.message.delete();
                        } catch (e) {
                            this.pSvc.alert$.next(e);
                        }
                        this.pSvc.loading$.next(false);;
                    }
                }
            ]
        })
    }

    async reply(lastMsg: ThreadMessage, medium?: EMedium) {
        let recipients: any[] = [];
        if (lastMsg.creator?._type === 'contacts') {
            let sender: Contact = new Contact(lastMsg.creator);
            if (lastMsg.fromEmail) {
                sender.email = lastMsg.fromEmail;
            }
            recipients = [sender];
        }

        if (!recipients.length && lastMsg.recipients?.length) {
            recipients = lastMsg.recipients.reduce((items, i) => {
                if (i._type === 'contacts' && !i.user_id) {
                    items.push(i);
                }
                return items;
            }, []);
        }

        if (medium === EMedium.NOTE && this.mSvc.thread$.getValue().medium === EMedium.CHAT) {
            medium = EMedium.CHAT;
        }

        if (!recipients?.length && medium === EMedium.EMAIL) {
            this.pSvc.alert$.next({
                title: 'No Recipients!',
                message: 'Cannot reply to internal users via email.'
            })
        } else {

            await this.mSvc.startMessage(
                this.parent,
                this.mSvc.thread$.getValue(),
                recipients,
                [],
                medium ? medium : (lastMsg.medium === EMedium.WEB) ? EMedium.EMAIL : lastMsg.medium,
                null,
                lastMsg
            );

        }

    }

    async replyAll(lastMsg: ThreadMessage) {

        let recipients: any[] = (lastMsg.creator?._type !== 'users') ? [lastMsg.creator] : [];
        recipients = recipients.concat(lastMsg.recipients || []);
        let cc: any[] = [];
        lastMsg.cc?.forEach(item => {
            if (!recipients?.find(i => i.id === item.id)) {
                cc.push(item);
            }
        });
        if (!recipients?.length && !cc?.length) {
            this.pSvc.notification$.next({
                title: 'No Recipients!',
                message: 'There are no users on this message to respond to.'
            });
        } else {

            await this.mSvc.startMessage(
                this.parent,
                this.mSvc.thread$.getValue(),
                recipients,
                cc,
                EMedium.EMAIL,
                null,
                lastMsg
            );
            // this.mSvc.msg$.next(null);

        }
    }

    async forward(lastMsg: ThreadMessage) {

        await this.mSvc.startMessage(
            this.parent,
            this.mSvc.thread$.getValue(),
            [],
            [],
            this.mSvc.thread$.getValue().medium,
            null,
            lastMsg,
            true
        );

    }

}
