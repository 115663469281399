"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollegeSeasonGame = exports.CollegeSeason = exports.RcrtStaffChange = exports.CollegeRef = exports.College = exports.COLLEGE_GENDERS = exports.COLLEGE_SPORTS = exports.RCRT_SCRAPING_ERRORS_MAP = exports.RCRT_SCRAPING_ERRORS = exports.allLinkTypes = exports.collegeLinkTypes = exports.eventLinkTypes = exports.LOCK_LEVELS = exports.COLLEGE_SUBDIVISIONS = exports.COLLEGE_DIVISIONS = exports.COLLEGE_SITE_PROVIDERS_MAP = exports.COLLEGE_SITE_PROVIDERS = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
var COLLEGE_SITE_PROVIDERS;
(function (COLLEGE_SITE_PROVIDERS) {
    COLLEGE_SITE_PROVIDERS[COLLEGE_SITE_PROVIDERS["NONE"] = 0] = "NONE";
    COLLEGE_SITE_PROVIDERS[COLLEGE_SITE_PROVIDERS["SIDEARM"] = 1] = "SIDEARM";
    COLLEGE_SITE_PROVIDERS[COLLEGE_SITE_PROVIDERS["PRESTO"] = 2] = "PRESTO";
    COLLEGE_SITE_PROVIDERS[COLLEGE_SITE_PROVIDERS["WMT"] = 3] = "WMT";
    COLLEGE_SITE_PROVIDERS[COLLEGE_SITE_PROVIDERS["PRESTO_ALT"] = 4] = "PRESTO_ALT";
    COLLEGE_SITE_PROVIDERS[COLLEGE_SITE_PROVIDERS["STREAMLINE"] = 5] = "STREAMLINE";
})(COLLEGE_SITE_PROVIDERS || (exports.COLLEGE_SITE_PROVIDERS = COLLEGE_SITE_PROVIDERS = {}));
exports.COLLEGE_SITE_PROVIDERS_MAP = {
    'None/Other': 0,
    'Sidearm': 1,
    'Presto': 2,
    'WMT': 3,
    'Streamline': 5
};
var COLLEGE_DIVISIONS;
(function (COLLEGE_DIVISIONS) {
    COLLEGE_DIVISIONS["NCAA"] = "NCAA";
    COLLEGE_DIVISIONS["NAIA"] = "NAIA";
    COLLEGE_DIVISIONS["NCCAA"] = "NCCAA";
    COLLEGE_DIVISIONS["JUCO"] = "Junior College";
    COLLEGE_DIVISIONS["NONE"] = "";
})(COLLEGE_DIVISIONS || (exports.COLLEGE_DIVISIONS = COLLEGE_DIVISIONS = {}));
var COLLEGE_SUBDIVISIONS;
(function (COLLEGE_SUBDIVISIONS) {
    COLLEGE_SUBDIVISIONS["D1"] = "Division 1";
    COLLEGE_SUBDIVISIONS["D2"] = "Division 2";
    COLLEGE_SUBDIVISIONS["D3"] = "Division 3";
    COLLEGE_SUBDIVISIONS["NONE"] = "";
})(COLLEGE_SUBDIVISIONS || (exports.COLLEGE_SUBDIVISIONS = COLLEGE_SUBDIVISIONS = {}));
var LOCK_LEVELS;
(function (LOCK_LEVELS) {
    LOCK_LEVELS[LOCK_LEVELS["NONE"] = 0] = "NONE";
    LOCK_LEVELS[LOCK_LEVELS["SOFT"] = 1] = "SOFT";
    LOCK_LEVELS[LOCK_LEVELS["HARD"] = 2] = "HARD";
    LOCK_LEVELS[LOCK_LEVELS["FAILED"] = 3] = "FAILED";
    LOCK_LEVELS[LOCK_LEVELS["LOCKED"] = 4] = "LOCKED";
})(LOCK_LEVELS || (exports.LOCK_LEVELS = LOCK_LEVELS = {}));
exports.eventLinkTypes = ['root', 'scouts', 'schedule'];
exports.collegeLinkTypes = ['root', 'home', 'staff', 'roster', 'schedule', 'x', 'instagram'];
exports.allLinkTypes = exports.eventLinkTypes.concat(exports.collegeLinkTypes);
var RCRT_SCRAPING_ERRORS;
(function (RCRT_SCRAPING_ERRORS) {
    RCRT_SCRAPING_ERRORS[RCRT_SCRAPING_ERRORS["ROOT_URL"] = 0] = "ROOT_URL";
    RCRT_SCRAPING_ERRORS[RCRT_SCRAPING_ERRORS["SPORT_URL"] = 1] = "SPORT_URL";
    RCRT_SCRAPING_ERRORS[RCRT_SCRAPING_ERRORS["STAFF_URL"] = 2] = "STAFF_URL";
    RCRT_SCRAPING_ERRORS[RCRT_SCRAPING_ERRORS["ROSTER_URL"] = 3] = "ROSTER_URL";
    RCRT_SCRAPING_ERRORS[RCRT_SCRAPING_ERRORS["NO_IMAGE"] = 4] = "NO_IMAGE";
    RCRT_SCRAPING_ERRORS[RCRT_SCRAPING_ERRORS["NO_STAFF_IMAGES"] = 5] = "NO_STAFF_IMAGES";
    RCRT_SCRAPING_ERRORS[RCRT_SCRAPING_ERRORS["STAFF_SCRAPE"] = 6] = "STAFF_SCRAPE";
    RCRT_SCRAPING_ERRORS[RCRT_SCRAPING_ERRORS["NO_STAFF"] = 7] = "NO_STAFF";
    RCRT_SCRAPING_ERRORS[RCRT_SCRAPING_ERRORS["MISSING_URL"] = 8] = "MISSING_URL";
    RCRT_SCRAPING_ERRORS[RCRT_SCRAPING_ERRORS["SCHEDULE_TEST_FAIL"] = 9] = "SCHEDULE_TEST_FAIL";
    RCRT_SCRAPING_ERRORS[RCRT_SCRAPING_ERRORS["SCHEDULE_ROW_FAIL"] = 10] = "SCHEDULE_ROW_FAIL";
    RCRT_SCRAPING_ERRORS[RCRT_SCRAPING_ERRORS["SCHEDULE_NO_GAMES"] = 11] = "SCHEDULE_NO_GAMES";
})(RCRT_SCRAPING_ERRORS || (exports.RCRT_SCRAPING_ERRORS = RCRT_SCRAPING_ERRORS = {}));
exports.RCRT_SCRAPING_ERRORS_MAP = {
    0: 'No Root Url',
    1: 'No Sport Url',
    2: 'No Staff Url',
    3: 'No Roster Url',
    4: 'No main image',
    5: 'No staff images',
    6: 'Unsupported staff page type. Needs scraping.',
    7: 'No staff found.',
    8: 'Missing a url',
    9: 'Schedule year test failed',
    10: 'No schedule rows found',
    11: 'No games found'
};
exports.COLLEGE_SPORTS = [
    {
        label: 'Soccer',
        value: 'soccer'
    },
    {
        label: 'Others Coming Soon',
        value: 'other'
    }
];
exports.COLLEGE_GENDERS = [
    {
        label: 'Men',
        value: 'men'
    },
    {
        label: 'Women',
        value: 'women'
    }
];
class College extends model_core_1.Base {
    constructor(data, olMap) {
        super('colleges', 14);
        this.title = '';
        this.links = [];
        this.sport = '';
        this.gender = null;
        this.image = null;
        this.staff = [];
        this.provider = COLLEGE_SITE_PROVIDERS.NONE;
        this.division = COLLEGE_DIVISIONS.NONE;
        this.subdivision = COLLEGE_SUBDIVISIONS.NONE;
        this.conference = '';
        this.state = '';
        this.city = '';
        this.address = null;
        this.tz = '';
        this.errors = {};
        this.errorCount = 0;
        this.record = '';
        this.seasons = new model_core_1.ChildArray();
        this._m = ['title', 'image'];
        this._s = ['title', 'staff', 'division', 'subdivision', 'conference', 'city', 'state', 'errors', 'image', 'gender', 'sport', 'record', 'errorCount'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, College);
        exports.collegeLinkTypes.forEach(id => {
            if (!this.getLink(id)) {
                this.add('links', { id: id, url: '', lock: 0, error: RCRT_SCRAPING_ERRORS.MISSING_URL });
            }
        });
    }
    getLink(id) {
        var _a;
        return (_a = this.links) === null || _a === void 0 ? void 0 : _a.find(l => l.id === id);
    }
    getStaffWithImages() {
        var _a;
        return ((_a = this.staff) === null || _a === void 0 ? void 0 : _a.reduce((withImg, s) => {
            var _a;
            if ((_a = s.image) === null || _a === void 0 ? void 0 : _a.src) {
                withImg.push(s);
            }
            return withImg;
        }, [])) || [];
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        if (!opts.properties) {
            opts.properties = [];
        }
        await super.loadAll(opts, stack, p);
    }
    toJSON() {
        var _a;
        let r = super.toJSON();
        r.errorCount = (_a = Object.keys(this.errors || {})) === null || _a === void 0 ? void 0 : _a.reduce((n, key) => {
            if (this.errors[key]) {
                n++;
            }
            return n;
        }, 0);
        return r;
    }
    save(parent) {
        var _a, _b, _c;
        if (!this.id) {
            throw `Cannot save college without id  (${this.title}).`;
        }
        if (!((_a = this.getLink('root')) === null || _a === void 0 ? void 0 : _a.url)) {
            throw `Cannot save college without root url (${this.title}-${this.id}).`;
        }
        // Remove any empty staff items
        if ((_b = this.staff) === null || _b === void 0 ? void 0 : _b.length) {
            for (let i = ((_c = this.staff) === null || _c === void 0 ? void 0 : _c.length) - 1; i >= 0; i--) {
                if (!this.staff[i].name) {
                    this.staff.splice(i, 1);
                }
                else if (!this.staff[i].title) {
                    this.staff.splice(i, 1);
                }
                else if (!this.staff[i].email && !this.staff[i].title.match(/coach/i)) {
                    this.staff.splice(i, 1);
                }
            }
        }
        return super.save(parent);
    }
    async toSearchJSON(opts) {
        let r = await super.toSearchJSON(opts);
        if (this.address.latitude && this.address.longitude) {
            r._geoloc = {
                lat: Number(this.address.latitude),
                lng: Number(this.address.longitude)
            };
        }
        return r;
    }
}
exports.College = College;
class CollegeRef extends model_core_1.Base {
    get results() {
        return {
            emails: this.results_emails,
            views: this.results_views,
            clicks: this.results_clicks,
            replies: this.results_replies
        };
    }
    constructor(data, olMap) {
        super('college_refs', 14);
        this.college = null;
        this.ref = null;
        this.notes = '';
        this.questionnaire = false;
        this.rating = { weight: 0 };
        this.results_emails = 0;
        this.results_views = 0;
        this.results_clicks = 0;
        this.results_replies = 0;
        this._m = ['ref'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, CollegeRef);
        // if (data) {
        //     console.log(this.id, this.results);
        // }
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        if (this.ref) {
            this.college = new College(opts.getDoc ? await opts.getDoc(this.ref) : await this.ref.get(), index_1.olm);
        }
        await super.loadAll(opts, stack, p);
    }
    toJSON() {
        var _a;
        let r = super.toJSON();
        r.ref = ((_a = this.college) === null || _a === void 0 ? void 0 : _a._docRef) || this.ref;
        delete r.college;
        return r;
    }
    calc(factors) {
        var _a, _b;
        if ((factors === null || factors === void 0 ? void 0 : factors.length) && ((_b = (_a = this.rating) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.length)) {
            this.rating.items.forEach(item => {
                let factor = factors.find(f => f.label.toLowerCase() === item.label.toLowerCase());
                if (factor === null || factor === void 0 ? void 0 : factor.importance) {
                    item.weight = Number(factor.importance || 0) * Number(item.rating || 0);
                }
                else {
                    item.weight = 0;
                }
            });
            this.rating.weight = this.rating.items.reduce((total, item) => {
                total += item.weight;
                return total;
            }, 0);
        }
    }
    async save(parent, setDoc) {
        var _a, _b, _c;
        if (!((_a = this.college) === null || _a === void 0 ? void 0 : _a._docRef) && !this.ref) {
            throw `Cannot save College reference until it has a docRef!`;
        }
        else {
            this.id = this.id || ((_b = this.college) === null || _b === void 0 ? void 0 : _b.id);
            this.active = (this.active !== null) ? this.active : true;
            this.last_date = Date.now();
            this.ref = ((_c = this.college) === null || _c === void 0 ? void 0 : _c._docRef) || this.ref;
        }
        return super.save(parent, setDoc);
    }
}
exports.CollegeRef = CollegeRef;
class RcrtStaffChange extends model_core_1.Base {
    constructor(data, olMap) {
        super('staff_changes', 14);
        this.additions = [];
        this.removals = [];
        this.ref = null;
        this.college = null;
        this._m = ['ref'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, CollegeRef);
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        if (this.ref) {
            this.college = new College(opts.getDoc ? await opts.getDoc(this.ref) : await this.ref.get(), index_1.olm);
        }
        await super.loadAll(opts, stack, p);
    }
}
exports.RcrtStaffChange = RcrtStaffChange;
class CollegeSeason extends model_core_1.Base {
    constructor(data, olMap) {
        super('seasons', 14);
        this.year = '';
        this.wins = null;
        this.losses = null;
        this.ties = null;
        this.errors = [];
        this.season_games = new model_core_1.ChildArray();
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, CollegeSeason);
    }
    toJSON() {
        let r = super.toJSON();
        delete r.season_games;
        return r;
    }
}
exports.CollegeSeason = CollegeSeason;
class CollegeSeasonGame extends model_core_1.Base {
    toString() {
        return `${this.location === 'h' ? 'vs ' : '@ '} ${this.opponent.title}: ${new Date(this.date).toString()}: ${this.result || ''} ${this.points_for || ''}-${this.points_against || ''}`;
    }
    constructor(data, olMap) {
        super('season_games', 6);
        this.result = null;
        this.points_for = null;
        this.points_against = null;
        this.location = null;
        this.ref = null;
        this.opponent = null;
        this.recapUrl = '';
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, CollegeSeasonGame);
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        if (this.ref) {
            this.opponent = new College(opts.getDoc ? await opts.getDoc(this.ref) : await this.ref.get(), index_1.olm);
        }
        await super.loadAll(opts, stack, p);
    }
    toJSON() {
        let r = super.toJSON();
        delete r.opponent;
        return r;
    }
    async save(parent, setDoc) {
        var _a, _b;
        if (!((_a = this.opponent) === null || _a === void 0 ? void 0 : _a._docRef) && !this.ref) {
            throw `Cannot save College reference until it has a docRef!`;
        }
        else {
            this.last_date = Date.now();
            this.ref = ((_b = this.opponent) === null || _b === void 0 ? void 0 : _b._docRef) || this.ref;
        }
        return super.save(parent, setDoc);
    }
}
exports.CollegeSeasonGame = CollegeSeasonGame;
index_1.olm.colleges = (ref, map) => {
    return new College(ref, map);
};
index_1.olm.college_refs = (ref, map) => {
    return new CollegeRef(ref, map);
};
index_1.olm.staff_changes = (ref, map) => {
    return new RcrtStaffChange(ref, map);
};
index_1.olm.seasons = (ref, map) => {
    return new CollegeSeason(ref, map);
};
index_1.olm.season_games = (ref, map) => {
    return new CollegeSeasonGame(ref, map);
};
