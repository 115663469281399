import {
    Component, EventEmitter, Inject, Input, OnChanges,
    Output, SimpleChanges
} from '@angular/core';
import {FireService, IFirestoreQuery} from '../_services/fire.service';
import {ScrollableDivDirective} from '../../shared/scrollable-div/scrollable-div';
import {BehaviorSubject, Subscription} from 'rxjs';
import {SpinnerComponent} from '../../shared/spinner/spinner.component';
import {AsyncPipe, NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';
import {PageService} from '../../shared/_services/page.service';
import {OnDestroyPage} from '../../shared/_inherited/ondestroy.page';
import {Base, formatDate, loadObject, Thread, ThreadMessage} from '@nxt/model-core';
import {DateHeaderComponent} from '../../shared/header/date-header.component';
import {PipesModule} from '../../shared/_pipes/pipes';
import {MessagingService} from '../_services/messaging.service';

// @ts-ignore
import {environment} from '@env/environment';
import {RouterModule} from '@angular/router';

@Component({
    standalone: true,
    imports: [
        ScrollableDivDirective, PipesModule, RouterModule,
        SpinnerComponent, AsyncPipe, NgForOf, NgTemplateOutlet, NgIf, DateHeaderComponent
    ],
    selector: 'scrollable-generic-list',
    template: `

        <div *ngIf="!(more$|async) && items && !items.length && !hideEmpty"
             class="bg-accent-100 m-4 border-t border-b border-dark-500 text-dark-700 px-4 py-3 flex max-w-400 m-auto mt-4"
        >
            <div class="flex-grow">
                <span class="font-bold">No {{label}} Found. </span> <span>{{noResultsMsg||''}}</span>
                <span *ngIf="source==='a'">
                    <br/><br/>
                    <a class="underline cursor-pointer" (click)="items=null;onClear.emit()">Clear Search</a>
                </span>
            </div>
        </div>
        
        <div scrollable (onScroll)="scroll($event)" [class]="class">
            <div class="item-list w-full {{padding}}">
                <ul role="list">
                    <li *ngFor="let item of items | filterBy: { term: searchTerm, properties: searchProperties }; let i = index;">
                        <date-header *ngIf="dateHeader" [label]="getDateHeader(item, i)"></date-header>
                        <ng-container
                                *ngTemplateOutlet="itemTemplate; context:{item:item, items:items, i:i}"></ng-container>
                    </li>
                </ul>
                <div class="m-auto mt-4 place-items-center w-full">
                    <button *ngIf="btnLabel" class="btn-sm btn-dark" (click)="onClick.emit()">Add Payment</button>    
                </div>
                <spinner class="m-1 mx-auto h-10 w-10 text-dark" *ngIf="more$|async"></spinner>
            </div>
        </div>
    `
})
export class ScrollableGenericList extends OnDestroyPage implements OnChanges {
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onNextPage: EventEmitter<any> = new EventEmitter<any>();
    @Input() path: string;
    @Input() label: string = 'Items';
    @Input() class: string = 'max-h-screen-header-search pb-20 overflow-y-auto';
    @Input() padding: string = '';
    @Input() pageSize: number = 15;
    @Input() itemTemplate: any;
    @Input() searchTerm: string = '';
    @Input() searchProperties: string[] = [];
    @Input() baseQuery: IFirestoreQuery[];
    @Input() dateHeader: string;
    @Input() dateHeaderPrefix: string = '';
    @Input() parent: any;
    @Input() watch: boolean;
    @Input() autoStart: boolean;
    @Input() items: any[];
    @Input() hideEmpty: boolean;
    @Input() exclude: Base;
    @Input() btnLabel: string;
    @Input() debug: boolean;
    @Input() loadAll: boolean;
    @Input() noResultsMsg: string;
    more$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    source: 'a' | 'f';
    lastItem: any;
    doneLoading: boolean;
    sortBy: string;
    env = environment;
    sub: Subscription;

    constructor(
        private fSvc: FireService,
        public pSvc: PageService,
        private mSvc: MessagingService
    ) {
        super();
    }

    // This handles static loader where there is no related algolia search happening first.
    // In the parent compoennt, set the 'source' and 'query' and 'path' properties and the load happens.
    ngOnChanges(changes: SimpleChanges) {
        if (this.debug) {
            console.log('this.autoStart',this.autoStart);
            console.log('this.path',this.path);
            console.log('this.baseQuery',this.baseQuery);
            console.log('changes.path.currentValue',changes.path?.currentValue);
            console.log('changes.path.previousValue',changes.path?.previousValue);
            console.log('changes.baseQuery.currentValue',changes.baseQuery?.currentValue);
            console.log('changes.baseQuery.previousValue',changes.baseQuery?.previousValue);
        }
        if (
            this.autoStart
            && this.path
            && this.baseQuery
            && (
                (changes.path?.currentValue && !changes.path?.previousValue)
                ||
                (changes.baseQuery?.currentValue && !changes.baseQuery?.previousValue)
            )
        ) {
            this.loadData(true);
        }
    }

    async loadData(clear?: boolean) {
        if (clear) {
            this.source = 'f';
            this.items = [];
        }
        if (this.source === 'f') {
            await this.load(this.baseQuery);
        }
    }

    async load(query: IFirestoreQuery[], append?: boolean, path?: string) {
        this.path = path || this.path;
        if (this.path && query) {

            if (!append) {
                this.items = [];
            }
            this.more$.next(true);
            if (this.pageSize) {
                query = query.concat([{name:'limit', args:[this.pageSize]}]);
            }
            this.sortBy = query.find(i => i.name==='orderBy')?.args[0];

            try {

                this.fSvc.getColl(this.path, query)
                    .subscribe(async res => {
                        if (res) {

                            if (!res.length) {
                                if (append) {
                                    this.doneLoading = true;
                                } else {
                                    this.items = [];
                                }
                            } else if (res.length) {
                                this.lastItem = res[res.length - 1];
                                let items: any[] = await Promise.all(res?.map(async item => {
                                    item = loadObject(item, {olm: this.mSvc.olm})
                                    if (item._type==='unread') {
                                        item = await this.loadUnread(item);
                                    }
                                    if (this.loadAll) {
                                        item.loadAll({default_client_id: environment.default_client?.id});
                                    }
                                    return item;
                                }));
                                if (append) {
                                    this.items = this.items.concat(items);
                                } else {
                                    this.items = items;
                                }
                            }
                        }

                        this.watchData(query);
                        this.more$.next(false);
                    },
                        e => {
                            console.warn(e.toString(), this.path);
                            if (e.toString().match(/permission-denied/)) {
                                this.pSvc.notification$.next({
                                    title: 'Permission Denied',
                                    message: `You do not have permissions configured for requested content. Please contact an admin for help.`
                                    // TODO Would be nice to have an email link or way to create a ticket.
                                })
                            }
                            this.more$.next(false);
                        });

            } catch (e) {
                console.error(e);
            }

        }
    }

    async loadUnread(item: any) {
        let obj: ThreadMessage | Thread = loadObject(await item.ref.get(), {olm: this.fSvc.olm});
        if (obj?._exists) {
            obj._unread = item;
        }
        return obj;
    }

    watchData(query: IFirestoreQuery[]) {
        if (this.watch && query) {
            let direction: string = query.find(i => i.name==='orderBy')?.args[1];

            if (this.lastItem) {
                query = query.concat({name: 'endAt', args: [this.lastItem]})
            }
            this.sub?.unsubscribe();
            this.sub = this.fSvc.watchState(this.path, query, this.d$)
                .subscribe(
                    async res => {
                        // Do a synchronous loop so the index locations are right. Async results in chaos in the array
                        // overlapping indexes as multiple process add/remove items without knowledge of each other.
                        for (let item of res) {
                            if (this.source === 'f') {
                                let i: Base = loadObject(item, {olm:this.mSvc.olm});
                                if (i._type==='unread') {
                                    i = await this.loadUnread(i);
                                }
                                let n: number = this.items.findIndex(m => m.id === i.id);
                                if (n === -1 && item.type === 'added') {
                                    let n: number = this.items.findIndex(m => {
                                        // console.log(sortBy+','+direction,eval(`m.${sortBy}`));
                                        // console.log('i.${sortBy}',eval(`i.${sortBy}`));
                                        if (direction === 'asc') {
                                            return eval(`m.${this.sortBy}`) > eval(`i.${this.sortBy}`)
                                        } else {
                                            return eval(`m.${this.sortBy}`) < eval(`i.${this.sortBy}`)
                                        }
                                    });
                                    if (n > -1) {
                                        // console.log(`add at ${n}`);
                                        this.items.splice(n, 0, i);
                                    } else if (direction==='desc') {
                                        // console.log(`add at end`);
                                        this.items.push(i);
                                    } else {
                                        // console.log(`add at start`);
                                        this.items.splice(0, 0, i);
                                    }

                                } else if (n > -1) {
                                    if (item.type === 'modified') {
                                        // console.log(`update at ${n}`, item);
                                        this.items[n] = i;
                                    } else if (item.type === 'removed' || item.type === 'deleted') {
                                        // console.log(`remove at ${n}`, item);
                                        this.items.splice(n, 1);
                                    }
                                }
                            }
                        }
                        // This was the only way to force the page to re-draw.
                        this.items = [...this.items];
                    },
                    e => {
                        console.warn(e.toString(), this.path);
                        if (e.toString().match(/permission-denied/)) {
                            this.pSvc.notification$.next({
                                title: 'Permission Denied',
                                message: `You do not have permissions configured for requested content. Please contact an admin for help.`
                                // TODO Would be nice to have an email link or way to create a ticket.
                            })
                        }
                        this.more$.next(false);
                    });
        }
    }

    async handleAlgoliaResults([results, append]) {
        this.source = 'a';

        let loadItem = (item) => {
            let i: Base = loadObject(item, {olm:this.mSvc.olm});
            let parts: string[] = item['objectID'].split('-');
            if (parts[1] !== 'undefined') {
                i._docRef = this.fSvc.fs.firestore.doc(parts[1]);
            } else {
                console.warn('BAD SEARCH RECORD', item);
            }
            Object.keys(item['_highlightResult']).forEach(p => {
                if (item['_highlightResult'][p].matchLevel==='full') {
                    i[`${p}_match`] = item['_highlightResult'][p].value;
                }
            });
            return i;
        };

        if (!append) {
            if (results?.hits?.length) {
                this.items = results?.hits?.reduce((items,item) => {
                    if (this.exclude) {
                        if (item?._type?.match(/threads/)) {
                            if (item?.object?.id
                                && item.object?.id !== this.exclude.id) {
                                items.push(loadItem(item));
                            }
                        } else if (item.id !== this.exclude.id) {
                            items.push(loadItem(item))
                        }
                    } else {
                        items.push(loadItem(item))
                    }
                    return items;
                },[]);
            } else {
                this.items = [];
            }
        } else if (results?.hits?.length) {
            this.items = this.items.concat(results?.hits?.reduce((items,item) => {
                if (!this.exclude || item.id !== this.exclude.id) {
                    items.push(loadItem(item))
                }
                return items;
            },[]));
        }
        this.more$.next(false);
    }

    scroll(n: number) {
        if (this.pageSize) {
            if (n && n > .9 && !this.more$.getValue()) {
                // console.log(this.items?.length, !(this.items?.length % (this.source==='a' ? 100 : this.pageSize)));
                if (!this.doneLoading && !(this.items?.length % (this.source==='a' ? 100 : this.pageSize))) {
                    this.more$.next(true);
                    this.nextPage();
                }
            }
        }
    }

    nextPage() {
        if (this.source === 'f') {
            if (this.lastItem) {
                let q: IFirestoreQuery[] = this.baseQuery.concat([{name: 'startAfter', args: [this.lastItem]}]);
                this.load(q, true);
            } else {
                this.more$.next(false);
            }
        } else {
            this.onNextPage.emit();
            this.more$.next(false);
        }
    }

    getDateHeader(item: any, n: number, property?: string) {
        property = property || this.sortBy || 'departure_date';
        if (item && item._type && item[property]) {
            let previous: any = this.items[n - 1];
            if (!item['_date']) {
                let d: number = eval(`item.${property}`);
                if (!d) {
                    if (item.legs?.length && item.legs[0].times?.takeoffUTC) {
                        d = item.legs[0].times?.takeoffUTC;
                    } else {
                        d = item.date;
                    }
                }
                item['_date'] = `${formatDate(new Date(d), this.dateHeader || 'MMM d')}`;
            }
            if (!previous || item['_date'] !== previous['_date']) {
                return this.dateHeaderPrefix+item['_date'];
            }
        }
    }
}
