import {ErrorHandler, Injectable} from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    handleError(err: any): void {
        const chunkFailedMessage = /ChunkLoadError/;
        if (chunkFailedMessage.test(err.message)) {
            if (confirm('Looks like there is a new version available that must be downloaded. Click OK to reload.')) {
                window.location.reload();
            }
        }
        if (
            !err.toString().match(/ExpressionChangedAfterItHasBeenCheckedError|triggerButtons/)
        ) {
            console.warn(err);
        }
    }
}
