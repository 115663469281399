import {Component, Input, OnInit, ViewChild, ViewContainerRef, Inject, AfterViewInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputImageComponent} from '@library/nxt/images/input-image.component';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {CloudFile} from '@nxt/model-core';
import {ClientService} from '@library/shared/_services/client.service';
import {FireService} from '@library/nxt/_services/fire.service';
import {PageService} from '@library/shared/_services/page.service';

@Component({
    selector: 'attachment-editor',
    standalone: true,
    imports: [CommonModule, InputImageComponent, IconsComponent],
    template: `
        <ng-container #attachmentContainer></ng-container>
        <ng-template #defaultTemplate>
            <div class="mt-2">
                <span *ngFor="let file of parent?.files; let i = index;"
                      class="cursor-pointer inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-dark-100 text-dark-700">
                    <a [href]="file.url" target="_blank">{{ file.name }}</a>
                    <button type="button" (click)="removeFile(i)"
                            class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-dark-400 hover:bg-dark-200 hover:text-dark-500 focus:outline-none focus:bg-dark-500 focus:text-white">
                        <span class="sr-only">Remove</span>
                        <icon name="heroicon-outline-x" class="h-2 w-2"></icon>
                    </button>
                </span>
                <input-image path="clients/{{cSvc.client_id}}/attachments/{{parent?._type}}/{{parent?.id}}/"
                             label="Attachments"
                             [crop]="false"
                             [collapse]="collapse"
                             [ignoreFileSize]="true"
                             [multiple]="true"
                             (uploadComplete)="updateFiles($event)">
                </input-image>
            </div>
        </ng-template>
    `
})
export class AttachmentEditorComponent implements AfterViewInit {
    @Input() parent: any;
    @Input() collapse: boolean;
    @Input() object?: any;
    @ViewChild('attachmentContainer', {read: ViewContainerRef, static: false}) attachmentContainer: ViewContainerRef;
    @ViewChild('defaultTemplate', {static: false}) defaultTemplate;
    attachmentComp: AttachmentEditorComponent;

    constructor(
        protected fSvc: FireService,
        public cSvc: ClientService,
        public pSvc: PageService,
        @Inject('LazyComponents') protected lazyComponents: any[]
    ) {
    }

    async ngAfterViewInit() {
        if(this.defaultTemplate || this.defaultTemplate) {
            await this.loadComponent();
        }
    }

    async loadComponent() {
        const selector: string = `${this.cSvc.name_key.toLowerCase()}-attachment-editor`;

        if (!this.attachmentComp || this.attachmentComp['_id'] !== selector) {
            let component: any;
            for (let c of this.lazyComponents) {
                for (let key of Object.keys(c)) {
                    if (key === selector) {
                        component = c[key];
                        break;
                    }
                }
            }

            if (!component?.loader) {
                this.loadDefaultTemplate();
                return;
            } else {
                try {
                    let imported = await component.loader();
                    const key = Object.keys(imported).find(key => !key.match(/Module/));
                    if (!key) {
                        this.loadDefaultTemplate();
                        return;
                    }
                    let module = imported[key];
                    let ref = this.attachmentContainer.createComponent(module);
                    this.attachmentComp = ref.instance as AttachmentEditorComponent;
                    this.attachmentComp['_id'] = selector;
                    this.attachmentComp.parent = this.parent;
                    this.attachmentComp.collapse = this.collapse;
                    this.attachmentComp.object =this.object;
                } catch (e) {
                    console.error(`Failed to load component ${selector}:`, e);
                    this.loadDefaultTemplate();
                }
            }
        } else {
            this.attachmentComp.parent = this.parent;
            this.attachmentComp.collapse = this.collapse;
            this.attachmentComp.object =this.object;
        }
    }

    loadDefaultTemplate() {
        if (this.defaultTemplate) {
            this.attachmentContainer.createEmbeddedView(this.defaultTemplate);
            console.log("loaded default template");
        } else {
            console.error('defaultTemplate is undefined');
        }
    }

    async updateFiles(files: any[]) {
        if (!this.parent.files) {
            this.parent.files = [];
        }
        for (let file of files) {
            try {
                let att: CloudFile = new CloudFile(file);
                att.setID();
                this.parent.files.push(att);
                if (!this.parent._docRef) {
                    this.parent._docRef = this.cSvc.client$.getValue()._docRef.collection(this.parent._type).doc(this.parent.id);
                }
                await att.save(this.parent);
            } catch (e) {
                console.error(e);
            }
        }
    }

    async removeFile(i: number) {
        let file: CloudFile = this.parent.files.splice(i, 1)[0];
        if (file._docRef && file._exists && file.delete) {
            await file.delete();
        }
        await this.fSvc.delete(file.url);
    }
}
