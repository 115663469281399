/**
 * These are components available for use by any NXT-powered site.
 */
export let sharedLazyComponents: any = {
    'faq': {
        t: 'FAQ Editor',
        d: 'FAQ Editor with sections that can be toggled open and closed',
        loader: () => import('./shared/faq/faq'),
        config: () => import('./shared/faq/faq-config')
    },
    'news-carousel': {
        t: 'News Carousel',
        d: 'News headlines with images in navigable carousel',
        loader: () => import('./shared/news-carousel/news-carousel.component'),
        config: () => import('./shared/news-carousel/news-carousel.config')
    },
    'rcrt-faq': {
        t: 'FAQ Editor (Recruit Messenger)',
        d: 'FAQ Editor with sections that can be toggled open and closed. Modified version used originally in Recruit Messenger.',
        loader: () => import('./shared/faq/rcrt-faq'),
        config: () => import('./shared/faq/rcrt-faq.config')
    },
    'change-log': {
        t: 'Change Log Component',
        d: `Uses rcrt-faq design to display last five change groups from the website's deployment history.`,
        loader: () => import('./shared/change-log/change-log')
    },
    'shared-pricing': {
        t: 'Pricing Grid',
        d: 'Show different features available at different account levels, along with pricing and Add to Cart functionality.',
        loader: () => import('./shared/pricing/shared-pricing'),
        config: () => import('./shared/pricing/shared-pricing.config')
    },
    'html-component': {
        t: 'HTML Component',
        d: 'Simple, raw html editor.',
        type: 'any',
        loader: () => import('./shared/html/html'),
        config: () => import('./shared/html/html-config')
    },
    'axek-content-two-colum-text-left-pic-right': {
        t: 'Content: Two-Column | Text on left | Right-bleed Pic on Right',
        d: 'Content section designed for A&K wood type/category page. Headline and text in left column.  Image on the right (which bleeds to the right edge).',
        loader: () => import('./shared/two-column/text-left-pic-right/content-two-colum-text-left-pic-right'),
        config: () => import('./shared/two-column/text-left-pic-right/content-two-colum-text-left-pic-right-config')
    },
    'axek-content-two-colum-pic-left-text-right': {
        t: 'Content: Two-Column | Left-bleed Pic on left | Text on Right',
        d: 'Content section designed for A&K wood type/category page. Headline and text in right column.  Image on the left (which bleeds to the left edge).',
        loader: () => import('./shared/two-column/pic-left-text-right/content-two-colum-pic-left-text-right'),
        config: () => import('./shared/two-column/pic-left-text-right/content-two-colum-pic-left-text-right-config')
    },
    'axek-content-two-colum-headline-left-text-right': {
        t: 'Content: Two-Column | Headline on left | Text on Right',
        d: 'Content section designed for A&K wood type/category page. Headline in left 1/3 column.  Text on right 2/3 column.',
        loader: () => import('./shared/two-column/headline-left-text-right/content-two-colum-headline-left-text-right'),
        config: () => import('./shared/two-column/headline-left-text-right/content-two-colum-headline-left-text-right-config')
    },
    'axek-content-two-colum-pic-left-text-right-with-bg': {
        t: 'Content: Two-Column | Pic on left | Text on Right | BG Image',
        d: 'Content section designed for A&K wood type/category page. Headline and text in right column.  Image on the left. BG image behind everything.',
        loader: () => import('./shared/two-column/pic-left-text-right-with-bg/content-two-colum-pic-left-text-right-with-bg'),
        config: () => import('./shared/two-column/pic-left-text-right-with-bg/content-two-colum-pic-left-text-right-with-bg-config')
    },
    'axek-product-other-gallery': {
        t: 'Products: Related/Other Products Gallery',
        d: 'Horizontal display of other products, used below the product-details section on a product page to keep shoppers engaged.',
        loader: () => import('./shared/other-gallery/product-other-gallery'),
        config: () => import('./shared/other-gallery/product-other-gallery-config')
    },
    'text-section-with-bg': {
        t: 'Text Section with Image BG',
        d: 'Simple content section with image background and text in the foreground.',
        loader: () => import('./shared/text-section-with-bg/text-section-with-bg'),
        config: () => import('./shared/text-section-with-bg/text-section-with-bg-config')
    },
    'axek-value-prop-cubes': {
        t: 'Value Proposition Cubes',
        d: 'Row of values in cubed grid.',
        loader: () => import('./shared/value-prop-cubes/value-prop-cubes'),
        config: () => import('./shared/value-prop-cubes/value-prop-cubes-config')
    },
    'axek-value-prop-hero': {
        t: 'Value Proposition Hero',
        d: 'Custom hero for A&K homepage with icon, text, value-prop cubes and another icon.',
        loader: () => import('./shared/value-prop-hero/value-prop-hero'),
        config: () => import('./shared/value-prop-hero/value-prop-hero-config')
    },
    'axek-value-prop-steps': {
        t: 'Value Proposition Steps',
        d: 'Custom hero for A&K homepage with 1-2-3 steps',
        loader: () => import('./shared/value-prop-steps/value-prop-steps'),
        config: () => import('./shared/value-prop-steps/value-prop-steps-config')
    },
    'axek-headline-and-text': {
        t: 'Headline and Text',
        d: 'Simple component, headline and text',
        loader: () => import('./shared/headline-with-text/headline-with-text'),
        config: () => import('./shared/headline-with-text/headline-with-text-config')
    },

    'axek-target-market-bar': {
        t: 'Target Markets Bar/Header',
        d: 'Bar/header listing target markets/user groups (e.g. fire pit boses, hotels, etc)',
        loader: () => import('./shared/target-market-bar/target-market-bar'),
        config: () => import('./shared/target-market-bar/target-market-bar-config')
    },
    'video-component': {
        t: 'Video Component',
        d: 'Component to input custom video',
        loader: () => import('./shared/video-component/video-component'),
        config: () => import('./shared/video-component/video-component-config')
    },
    'video-side-component': {
        t: 'Video Side Component',
        d: 'Component to input custom video on left or right',
        loader: () => import('./shared/video-component/video-side-component'),
        config: () => import('./shared/video-component/video-side-component-config')
    },
    'video-single-column': {
        t: 'Video Single Column',
        d: 'Component to input custom video in a column with text above',
        loader: () => import('./shared/video-component/video-single-column'),
        config: () => import('./shared/video-component/video-single-column-config')
    },
    'full-width-image-component': {
        t: 'Full Width Image Component',
        d: 'Full Width Image Component for EW',
        loader: () => import('./shared/full-width-image/full-width-image'),
        config: () => import('./shared/full-width-image/full-width-image-config')
    },
    'button-with-background-component': {
        t: 'Button With Background',
        d: 'Button with settable background color',
        loader: () => import('./shared/buttons/button-with-bg'),
        config: () => import('./shared/buttons/button-with-bg-config')
    },
    'axek-tabbed-video-component': {
        t: 'Video Tabs Component',
        d: 'Video Component that allows you to select which videos are showing based on which target market you click',
        loader: () => import('./shared/tabbed-video-component/tabbed-video-component'),
        config: () => import('./shared/tabbed-video-component/tabbed-video-component-config')
    },
    'reviews-component': {
        t: 'Customer Reviews Grid',
        d: 'Component that allows for reviews to be displayed in a grid',
        loader: () => import('./shared/reviews/reviews'),
        config: () => import('./shared/reviews/reviews-config')
    },
    'dual-button-with-background-component': {
        t: 'Dual Buttons With Background',
        d: 'Two buttons with settable background color',
        loader: () => import('./shared/buttons/dual-button-with-bg'),
        config: () => import('./shared/buttons/dual-button-with-bg-config')
    },
    'support-how-to': {
        t: 'Support: How-To/Tutorial',
        d: 'Tutorial editor with navigable table-of-contents, section header/text/video/image.',
        loader: () => import('./shared/support/how-to'),
        config: () => import('./shared/support/how-to-config')
    },
    'app-carousel': {
        t: 'Image Carousel',
        d: 'Image Carousel with Image Zoom,Filtering,Autoplay, Mobile Swiping, Thumbnails, and Dot Thumbnails',
        loader: () => import('./shared/carousel/app-carousel.component'),
        config: () => import('./shared/carousel/app-carousel-config')
    },
    'axek-centered-headline-with-text': {
        t: 'Headline Centered with text centered under it',
        d: 'Headline with text under it',
        loader: () => import('./shared/content/centered-headline/centered-headline-with-text'),
        config: () => import('./shared/content/centered-headline/centered-headline-with-text-config')
    },
    'contact-us-form': {
        t: 'Form For Contact',
        d: 'Contact us form with editable headline, button text, background color, and html section.',
        loader: () => import('./shared/contact-us/contact-us-form'),
        config: () => import('./shared/contact-us/contact-us-form-config')
    },
    'breadcrumb-component': {
        t: 'Breadcrumbs',
        loader: () => import('./shared/breadcrumb/gmtl-breadcrumb')
    },
    'blog-article-grid-component': {
        t: 'Blog Article Grid',
        d: 'Grid of article images for users to click on and view the article',
        loader: () => import('./shared/blog/shared-blog-article-grid'),
        config: () => import('./shared/blog/shared-blog-article-grid-config')
    },
    'content-number-showcase': {
        t:'Mid Content section that has cards in the middle',
        d: 'Mid Content section that has cards in the middle',
        loader: () => import('./shared/content-number-showcase/content-number-showcase'),
        config: () => import('./shared/content-number-showcase/content-number-showcase-config')
    },
    'installation-hero-sliders': {
        t:'Hero Image Carousel',
        d: 'Hero Component with rotating image background',
        loader: () => import('./shared/installation-hero-sliders/installation-hero-sliders'),
        config: () => import('./shared/installation-hero-sliders/installation-hero-sliders-config')
    },

}
