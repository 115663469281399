import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {FireService, IFirestoreQuery} from '../_services/fire.service';
import {ScrollableDivDirective} from '../../shared/scrollable-div/scrollable-div';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {SpinnerComponent} from '../../shared/spinner/spinner.component';
import {AsyncPipe, NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';
import {ClientService} from '../../shared/_services/client.service';
import {PageService} from '../../shared/_services/page.service';
import {map, takeUntil} from 'rxjs/operators';
import {OnDestroyPage} from '../../shared/_inherited/ondestroy.page';
import {Base, Thread, ThreadMessage, loadObject} from '@nxt/model-core';
import {DateHeaderComponent} from '../../shared/header/date-header.component';
import {PipesModule} from '../../shared/_pipes/pipes';
import {MessagingService} from '../_services/messaging.service';
import {MessageItemComponent} from '../../../nxto/src/app/threads/_components/message-item.component';

@Component({
    standalone: true,
    imports: [
        ScrollableDivDirective, PipesModule,
        SpinnerComponent, AsyncPipe, NgForOf,
        NgTemplateOutlet, NgIf, DateHeaderComponent, MessageItemComponent
    ],
    selector: 'scrollable-thread-triggered-list',
    template: `

        <div *ngIf="!(more$|async) && items && !items.length && thread._exists"
             class="bg-accent-100 m-4 border-t border-b border-dark-500 text-dark-700 px-4 py-3 flex max-w-400 m-auto mt-4"
        >
            <div class="flex-grow">
                <span class="font-bold">No Messages Found. </span>
            </div>
        </div>
        
        <div scrollable (onScroll)="scroll($event)" [class]="class">
            <div class="item-list w-full">
                <ul role="list">
                    <ng-container *ngFor="let item of items; let i = index;">
                        <li *ngIf="item._exists && item.tRef?.path === thread._docRef?.path">
                            <ng-container
                                    *ngTemplateOutlet="itemTemplate; context:{item:item, items:items, i:i}"></ng-container>
                        </li>
                    </ng-container>
                </ul>
                <spinner class="m-1 mx-auto h-10 w-10 text-dark" *ngIf="more$|async"></spinner>
            </div>
        </div>
        
        <ng-template let-item="item" let-items="items" let-i="i" #itemTemplate>
            <message-item
                [index]="i"
                (onSelect)="mSvc.msg$.next($event)"
                [selected]="selected?.id === item.id"
                (onDelete)="handleDelete($event)"
                [message]="item"
                [parent]="mSvc.context$|async"></message-item>
        </ng-template>
    `
})
export class ScrollableThreadTriggeredList extends OnDestroyPage {
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @Output() onNextPage: EventEmitter<any> = new EventEmitter<any>();
    @Output() onItemsLoaded: EventEmitter<any> = new EventEmitter<any>();
    @Input() class: string = 'max-h-screen-header-search pb-20 overflow-y-auto';
    @Input() pageSize: number = 15;
    @Input() itemTemplate: any;
    @Input() thread: Thread;
    @Input() items: any[];
    path: string;
    baseQuery: IFirestoreQuery[];

    more$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    lastItem: any;
    doneLoading: boolean;
    sortBy: string;
    selected: ThreadMessage;
    
    constructor(
        private fSvc: FireService,
        private cSvc: ClientService,
        public pSvc: PageService,
        public mSvc: MessagingService,
        public eRef: ElementRef
    ) {
        super();
        combineLatest([mSvc.msg$, mSvc.thread$])
            .pipe(takeUntil(this.d$))
            .pipe(map(res => {
                return {m: res[0], t: res[1]};
            }))
            .subscribe(result => {
                if (result.m?.id !== this.selected?.id) {
                    this.selected = result.m;
                }

                if (result.t?.id && result.t?.id !== this.thread?.id) {
                    this.items = [];
                    this.thread = result.t;
                    if (!this.thread._docRef) {
                        this.thread._docRef = this.cSvc.client$.getValue()._docRef.collection('threads').doc(this.thread.id);
                    }
                    this.path = `clients/${this.cSvc.client_id}/threadsmsgs`;
                    this.baseQuery = [
                        {name: 'where', args: ['tRef','==', this.thread._docRef]},
                        {name: 'orderBy', args: ['date', 'desc']}
                    ];
                    this.load(this.baseQuery);
                }
            });
    }

    async load(query: IFirestoreQuery[], append?: boolean, path?: string) {

        if (!append) {
            this.items = [];
        }
        this.path = path || this.path;
        if (this.pageSize) {
            query = query.concat([{name:'limit', args:[this.pageSize]}]);
        }

        try {
            this.more$.next(true);
            this.fSvc.getColl(this.path, query, this.d$)
                .subscribe(async res => {
                    if (res) {
                        if (!res.length) {
                            if (append) {
                                this.doneLoading = true;
                            } else {
                                this.items = [];
                            }
                        } else if (res.length) {
                            this.lastItem = res[res.length - 1];
                            let items: any[] = await Promise.all(res?.map(async item => {
                                try {
                                    let i = loadObject(item, {olm:this.fSvc.olm});
                                    // calling loadAll to get attachments.
                                    // await i.loadAll({properties: ['files'], loadAllFn: this.mSvc.loadAll, olm: this.mSvc.olm});
                                    return i;
                                } catch (e) {
                                    console.warn(e, item.id);
                                    return item;
                                }
                            }));
                            if (append) {
                                this.items = this.items.concat(items);
                            } else {
                                this.items = items;
                            }
                        }
                    }
                    this.more$.next(false);
                    this.watchData(query);
                    this.onItemsLoaded.emit(this.items);
                });

        } catch (e) {
            console.error(e);
            this.more$.next(false);
        }

    }

    watchData(query: IFirestoreQuery[]) {
        if (query) {

            let sortBy: string = query.find(i => i.name==='orderBy')?.args[0];
            this.sortBy = sortBy;
            let direction: string = query.find(i => i.name==='orderBy')?.args[1];

            if (this.lastItem) {
                query = query.concat({name: 'endAt', args: [this.lastItem]})
            }
            this.fSvc.watchState(this.path, query, this.d$)
                .subscribe(async res => {
                    // Do a synchronous loop so the index locations are right. Async results in chaos in the array
                    // overlapping indexes as multiple process add/remove items without knowledge of each other.
                    for (let item of res) {
                        let i: Base =  loadObject(item, {olm:this.fSvc.olm});
                        await i.loadAll({properties: ['files'], loadAllFn: this.mSvc.loadAll, olm: this.mSvc.olm});
                        let n: number = this.items.findIndex(m => m.id === i.id);
                        if (n === -1 && item.type === 'added') {
                            let n: number = this.items.findIndex(m => {
                                // console.log(sortBy+','+direction,eval(`m.${sortBy}`));
                                // console.log('i.${sortBy}',eval(`i.${sortBy}`));
                                if (direction === 'asc') {
                                    return eval(`m.${sortBy}`) > eval(`i.${sortBy}`)
                                } else {
                                    return eval(`m.${sortBy}`) < eval(`i.${sortBy}`)
                                }
                            });
                            if (n > -1) {
                                // console.log(`add at ${n}`);
                                this.items.splice(n, 0, i);
                            } else if (direction==='desc') {
                                // console.log(`add at end`);
                                this.items.push(i);
                            } else {
                                // console.log(`add at start`);
                                this.items.splice(0, 0, i);
                            }

                        } else if (n > -1) {
                            if (item.type === 'modified') {
                                // console.log(`update at ${n}`, item);
                                this.items[n] = i;
                            } else if (item.type === 'removed' || item.type === 'deleted') {
                                // console.log(`remove at ${n}`, item);
                                this.items.splice(n, 1);
                            }
                        }

                    }
                    // This was the only way to force the page to re-draw.
                    this.items = [...this.items];
                });
        }
    }

    scroll(n: number) {
        if (this.pageSize) {
            if (n && n > .9 && !this.more$.getValue()) {
                // console.log(this.items?.length, !(this.items?.length % (this.source==='a' ? 100 : this.pageSize)));
                if (!this.doneLoading && !(this.items?.length % this.pageSize)) {
                    this.more$.next(true);
                    this.nextPage();
                }
            }
        }
    }

    nextPage() {
        if (this.lastItem) {
            let q: IFirestoreQuery[] = this.baseQuery.concat([{name: 'startAfter', args: [this.lastItem]}]);
            this.load(q, true);
        } else {
            this.more$.next(false);
        }
    }

    handleDelete(msg: ThreadMessage) {
        if (!this.items?.length || (this.items?.find(i => i.id === msg.id) && this.items?.length === 1)) {
            this.thread.delete();
        }
    }


    print() {

        let styles: string = 'styles.css';
        let tags = document.getElementsByTagName('link');
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].href.match(/styles.css$/)) {
                styles = tags[i].href;
                break;
            }
        }
        if (!styles) {
            styles = `${window.location.origin}/styles.css`;
        }
        console.log(styles);
        var mywindow = window.open('', 'PRINT', 'height=800,width=1160');
        mywindow.document.write(`<html><head><title>Thread Details</title><link rel="stylesheet" href="${styles}"></head>`);
        mywindow.document.write('</head><body >');
        mywindow.document.write(this.eRef.nativeElement.innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
    }
}
