"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RcrtClubUser = exports.RcrtClub = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class RcrtClub extends model_core_1.Base {
    constructor(data, olMap) {
        super('clubs', 14);
        this.name = '';
        this.phone = '';
        this.address = null;
        this.max = null;
        this.players = null;
        this.club_users = new model_core_1.ChildArray();
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, RcrtClub);
    }
}
exports.RcrtClub = RcrtClub;
class RcrtClubUser extends model_core_1.Base {
    constructor(data, olMap) {
        super('club_users', 14);
        this.user = null;
        this.type = index_1.RCRT_USER_TYPE.NONE;
        this.role = '';
        data = (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, RcrtClubUser);
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
    }
    toJSON() {
        var _a;
        if (!((_a = this.user) === null || _a === void 0 ? void 0 : _a.id)) {
            throw `Cannot save RcrtClubUser without user.id!`;
        }
        else {
            this.id = this.user.id;
        }
        return super.toJSON();
    }
}
exports.RcrtClubUser = RcrtClubUser;
index_1.olm.clubs = (ref, map) => {
    return new RcrtClub(ref, map);
};
index_1.olm.club_users = (ref, map) => {
    return new RcrtClubUser(ref, map);
};
