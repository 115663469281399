import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {Client} from '@nxt/model-core';

import {ThemeService} from './theme.service';
import {PageService} from './page.service';
import {ClientService} from './client.service';

// @ts-ignore
import {environment} from '@env/environment';
import {LocalStorageService} from './local-storage.service';


@Injectable()
export class ClientGuard  {
    constructor(
        private cSvc: ClientService,
        private pSvc: PageService,
        private tSvc: ThemeService,
        private lSvc: LocalStorageService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return new Promise(async (resolve) => {
            let name_key: string = (route.params.name_key || environment.default_client.name_key).toString();
            try {
                let result: any = await this.cSvc.callAPI(`/cms/client/${name_key}`, 'get');
                let client: any = new Client(result);
                if (client?.id) {
                    this.cSvc.c$.next(client);
                    await this.tSvc.setTheme(client.theme);
                } else {
                    this.lSvc.saveState('lastPage', '');
                    this.lSvc.saveGState('lastPage', '');
                    resolve(false);
                }
            } catch (e) {
                console.warn(e);
            }
            resolve(true);
        });
    }

}
