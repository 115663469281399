export let rcrtLazyComponents: any = {
   'rcrt-contact-us': {
      t: 'Contact Us/Help Form',
      client: 'RCRT',
      loader: () => import('./contact-us/rcrt-contact-us'),
      config: () => import('./contact-us/rcrt-contact-us.config')
   },
   'rcrt-home-hero': {
      t: 'Recruit Messenger Home Page Hero',
      client: 'RCRT',
      loader: () => import('./home-hero/rcrt-home-hero'),
      config: () => import('./home-hero/rcrt-home-hero.config')
   },
   'rcrt-public-event': {
      t: 'Event Highlight Grid',
      d: 'Grid of event icons leading to public-facing event details pages, showing how good our data is.',
      client: 'RCRT',
      loader: () => import('./public-events/rcrt-public-events'),
      config: () => import('./public-events/rcrt-public-events.config')
   },
   'rcrt-multi-column-content-feature': {
      t: 'Multi-Content Column Feature',
      d: 'Multi-column content feature. Source: https://tailwindui.com/components/marketing/sections/feature-sections',
      client: 'RCRT',
      loader: () => import('./multi-column-content-feature/rcrt-multi-column-content-feature'),
      config: () => import('./multi-column-content-feature/rcrt-multi-column-content-feature.config')
   },
   'rcrt-footer': {
      t: 'Recruit Messenger Footer',
      d: 'Standard footer for RM site.',
      client: 'RCRT',
      loader: () => import('./footer/rcrt-footer'),
      config: () => import('./footer/rcrt-footer.config')

   },
   'rcrt-nxt-product-details': {
      t: 'Product editor component used in RCRT only.',
      client: 'RCRT',
      loader: () => import('../../../../nxto/src/app/_clients/rcrt/_components/rcrt-product-details')
   },
   'rcrt-pricing': {
      t: 'Pricing Grid',
      d: 'Show different features available at different account levels, along with pricing and Add to Cart functionality.',
      client: 'RCRT',
      loader: () => import('./pricing/rcrt-pricing'),
      config: () => import('./pricing/rcrt-pricing.config')
   },
   'rcrt-nxt-order-details': {
      t: 'Order details component used in NXT only.',
      client: 'NXT',
      loader: () => import('../../../../nxto/src/app/_clients/rcrt/_components/rcrt-order-details')
   },
};
