import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import algoliasearch from 'algoliasearch/lite';

import {
    AlgoliaQueryBuilder, AlgoliaQueryFacetFilterItem, Filter,
    IAlgoliaQueryFacetFilterItem, IAlgoliaSearchRequest, IAlgoliaSearchResults, IMenuItem, User
} from '@nxt/model-core';
import {SpinnerComponent} from '../spinner/spinner.component';
import {FiltersDialog} from '../../nxt/search/filters.dialog';
import {TabBarComponent} from '../../nxt/tabs/tab-bar.component';
import {LocalStorageService} from '../_services/local-storage.service';
import {PageService} from '../_services/page.service';
import {ClientService} from '../_services/client.service';
import {FireService, IFirestoreQuery} from '../../nxt/_services/fire.service';
import {Router} from '@angular/router';
import {AccountService} from '../../nxt/_services/account.service';
// @ts-ignore
import {environment} from '@env/environment';
import {OnDestroyPage} from '../_inherited/ondestroy.page';
import {IconsComponent} from '../icons/icons.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, FormsModule,
        SpinnerComponent, TabBarComponent,
        FiltersDialog, IconsComponent
    ],
    selector: 'algolia-search-component',
    template: `
        <div class="flex items-stretch w-full p-1" *ngIf="builder">
            <div *ngIf="filterSet">
                <button (click)="showFilters()" class="btn-clear">
                    <icon name="heroicon-outline-filter" class="h-4 w-4 mr-1"></icon>
                    <span class="hidden md:block">Filters</span>
                </button>
            </div>
            <div class="grow w-full">
                <div class="flex rounded-full shadow-sm">
                    <div class="relative flex items-stretch flex-grow">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <icon *ngIf="!(searching$|async)" name="heroicon-outline-search" class="h-5 w-5 text-gray-400"></icon>
                            <spinner *ngIf="searching$|async" class="h-4 w-4 text-accent"></spinner>
                        </div>
                        <input type="text" [id]="id" (keyup)="handleKeyup($event)" [(ngModel)]="builder.query"
                               class="focus:ring-dark-500 focus:border-dark-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                               [placeholder]="placeholder||''">
                        <div *ngIf="builder.query" (click)="clearTerm()"
                             class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center">
                            <icon name="heroicon-outline-x" class="h-5 w-5 text-gray-400"></icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex p-1 w-full" *ngIf="showChicklets">
            <span *ngFor="let filter of builder?.namedFilterItems; let i = index;"
                  [style.backgroundColor]="filter.color"
                  class="chicklet flex m-1">
                <span (click)="removeFilter(filter);" [style.color]="filter.contrast">{{filter.name}}</span>
                <span *ngIf="i < (builder.namedFilterItems.length-1) && (filter.type==='or'||filter.type==='and')" (click)="toggleAnd();" class="ml-2" [style.color]="filter.contrast">{{ builder.and ? 'and' : 'or' }}</span>
            </span>
            <div (click)="clearTerm()" *ngIf="nbHits" class="chicklet flex m-1  place-content-center space-y-2 text-dark bg-gray-200">
                <div>Results: {{nbHits}}</div>
                <icon *ngIf="builder.query" name="heroicon-outline-x" class="ml-1 h-4 w-4 text-white -mt-1"></icon>
            </div>
        </div>
    `
})
export class AlgoliaSearchComponent extends OnDestroyPage implements OnInit {
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @Output() onResults: EventEmitter<[IAlgoliaSearchResults, boolean]> = new EventEmitter<[IAlgoliaSearchResults, boolean]>();
    @Output() onSearching: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() label: string;
    @Input() placeholder: string;
    @Input() id: string;
    @Input() types: string[];
    @Input() index: string;
    @Input() minLength: number;
    @Input() saveSearch: boolean = true;
    @Input() hitsPerPage: number = 100;
    @Input() preSearchLogic: Function;
    @Input() filterSet: IAlgoliaFilterSet[];
    @Input() builder: AlgoliaQueryBuilder;
    @Input() startAfter: number;
    @Input() autoStart: boolean;
    @Input() showChicklets: boolean = true;
    @Input() emptyOnClear: boolean = false;
    @Input() _env: string = environment.type;
    searching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showMore$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    scrollSub: Subscription;
    nbHits: number;

    constructor(
        public pSvc: PageService,
        private cSvc: ClientService,
        private lSvc: LocalStorageService,
        private router: Router,
    ) {
        super();
    }


    ngOnInit() {
        if (this.index) {
            // Load user's stored search state for this search
            this.builder = new AlgoliaQueryBuilder( (this.saveSearch !== false) ? this.lSvc.localState[`${this.cSvc.name_key}-${this.index}-request`] : null);
            this.builder.build();
            this.builder.type = '';
        }
        this.searchOrClear();

        // Monitor scroll.  When near the bottom, load the next page.
        this.scrollSub?.unsubscribe();
        this.scrollSub = this.pSvc.scroll$
            .pipe(takeUntil(this.pSvc.nav$))
            .subscribe(
                res => {
                    if (res && res > .9
                        && this.showMore$.getValue()
                        && !this.searching$.getValue()
                        && (this.builder.namedFilterItems?.length || this.builder.query)
                    ) {
                        this.searching$.next(true);
                        this.nextPage();
                    }
                }
            );
    }

    searchOrClear() {
        if (this.index && !this.emptyOnClear && (this.autoStart || this.builder.query || this.builder.filterItems?.length)) {
            this.search();
        } else {
            this.onClear.emit(true);
            this.nbHits = 0;
            this.saveState();
        }
    }

    clearTerm(includeFilters?: boolean) {
        this.builder.query = '';
        this.showMore$.next(false);
        this.nbHits = 0;
        if (includeFilters || this.preSearchLogic) {
            this.builder.filterItems = [];
        }
        this.searchOrClear();
    }

    nextPage() {
        this.builder.page++;
        this.search(true);
    }

    extractHighlights(parent, n) {
        n = n || 0;
        Object.keys(parent)?.forEach(p => {
            if (parent[p].matchLevel === 'none') {
                delete parent[p];
            } else if (typeof parent[p] === 'object' && !parent[p].matchLevel) {
                parent[p] = this.extractHighlights(parent[p], n)
            } else {
                switch (parent[p].matchLevel) {
                    case 'full':
                        n += 2;
                        break;
                    case 'partial':
                        n += 1;
                        break;
                }
            }
        });
        return n;
    }

    async search(append?: boolean) {
        this.searching$.next(true);
        try {
            if (this.preSearchLogic) {
                this.builder = this.preSearchLogic(this.builder);
            }
            this.builder.hitsPerPage = this.hitsPerPage;

            let result: any;

            const aClient = algoliasearch(environment.algolia.appId, environment.algolia.searchKey);
            const index = aClient.initIndex(this.index);

            let req: IAlgoliaSearchRequest = Object.assign({}, this.builder.toSearchRequest());
            if (this.index !== 'airports') {
                if (this._env) {
                    req.params.facetFilters.push([`_env:${this._env}`]);
                }
                if (this.index !== 'clients') {
                    req.params.facetFilters.push([`_client:${this.cSvc.client_id}`]);
                }
            }
            this.onSearching.emit(true);
            result = await index.search(req.query, req.params);
            this.nbHits = result?.nbHits || 0;
            if (result) {
                this.showMore$.next(result?.hits?.length && !(result?.hits?.length % this.builder.hitsPerPage));
                this.onResults.emit([result, append]);
            }
            this.saveState();

        } catch (e) {
            console.warn(e, e?.message);
            e.title = e.title || e.name;
            this.pSvc.alert$.next(e);
            this.searching$.next(false);
        }

        // this.watch();
        // Wait a half-second to hide the spinner.
        setTimeout(() => {
            this.searching$.next(false);
        }, 500);
        // this.pSvc.loading$.next(false);
    }

    handleKeyup(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            if (
                (this.minLength && this.builder.query?.length >= this.minLength)
                || (!this.minLength && this.builder.query?.length)
            ) {
                this.builder.page = 0;
                this.search();
            } else {
                this.clearTerm();
            }
        } else if (this.startAfter && this.builder.query.length > this.startAfter) {
            this.search();
        } else if (!this.builder.query?.length) {
            this.clearTerm();
        }
    }

    removeFilter(filter: AlgoliaQueryFacetFilterItem) {
        this.builder.removeFilter(filter);
        this.searchOrClear();
    }

    toggleAnd() {
        this.builder.and = !this.builder.and;
        this.searchOrClear();
    }

    saveState() {
        if (this.saveSearch) {
            this.lSvc.saveState(`${this.cSvc.name_key}-${this.index}-request`, this.builder.toJSON());
        }
    }

    toggleFilter(filter) {
        if (this.builder.filterItems.find(i => i.id === filter.id)) {
            this.builder.removeFilter(filter);
        } else {
            this.builder.addFilter(filter);
        }
        this.searchOrClear();
    }

    showFilters() {

        this.pSvc.modal$.next({
            component: FiltersDialog,
            label: 'Filters',
            onLoaded: (comp: FiltersDialog) => {
                comp.filterSet = this.filterSet;
                comp.builder = this.builder;
                if (this.filterSet?.length) {
                    comp.buildButtons(this.filterSet[0])
                }
                let sub = comp.onToggle.subscribe(
                    filter => {
                        if (filter) {
                            this.toggleFilter(filter);
                            comp.builder = this.builder;
                        }
                    }
                );
                comp.onClose.pipe(take(1)).subscribe(
                    c => {
                        sub?.unsubscribe();
                        this.searchOrClear();
                    }
                );
            }
        })
    }
}

export interface IAlgoliaFilterSet {
    name: string;
    items: IAlgoliaQueryFacetFilterItem[],
    buttons?: IMenuItem[]
}

export async function getManualFilterSet(
    cSvc: ClientService,
    fSvc: FireService,
    pSvc: PageService,
    router: Router,
    type?: string
): Promise<IAlgoliaFilterSet> {

    let result: IAlgoliaFilterSet
    try {
        let query: IFirestoreQuery[] = [{ name: 'where', args: ['active', '==', true] }];
        if (type) {
            query.push({ name: 'where', args: ['type', '==', type] });
        }
        let res: any = await fSvc.getColl(`clients/${cSvc.client_id}/filters`, query).toPromise();

        result = {
            name: 'Manual Filters',
            buttons: [
                {
                    label: 'Manage Manual Filters',
                    class: 'btn-dark',
                    click: () => {
                        pSvc.clickEsc$.next(true);
                        switch (type) {
                            case 'quotes':
                                router.navigate([`/${cSvc.name_key}/quotes/filters`])
                                break;
                            default:
                                router.navigate([`/${cSvc.name_key}/threads/filters`])
                                break;
                        }

                    }
                }
            ],
            items: res?.map(item => {
                let filter: Filter = new Filter(item);
                let fItem = new AlgoliaQueryFacetFilterItem();
                fItem.id = filter.id;
                fItem.name = filter.name;
                fItem.color = filter.color || '#333';
                fItem.contrast = filter.contrast || 'yellow';
                fItem.key = 'filters.id';
                fItem.type = 'or';
                fItem.value = `filters.id:${item.id}`;
                return fItem;
            })
        };
    } catch (e) {
        console.warn(e, 'filters');
    }


    return result;
};

export async function getUsersByRoleFilterSets(
    aSvc: AccountService,
    cSvc: ClientService
): Promise<IAlgoliaFilterSet[]> {
    let results: IAlgoliaFilterSet[] = [];

    let roles: any[] = cSvc.client$.getValue()?.config?.roles||[];
    let uBR: any = await aSvc.getUsersByRole();
    Object.keys(uBR).forEach(role => {
        if (role !== 'date' && roles?.find(r => r.id === role) && roles?.find(r => r.id === role).show_in_filters) {
            let set: IAlgoliaFilterSet = {
                name: `Agent: ${role.toUpperCase()}`,
                items: uBR[role].map((u: User) => {
                    let i: AlgoliaQueryFacetFilterItem = new AlgoliaQueryFacetFilterItem();
                    i.id = u.id;
                    i.name = u.name;
                    i.value = `agent_id:${u.id}`;
                    i.color = '#dfdfdf';
                    i.contrast = '#000000';
                    i.type = 'or';
                    return i;
                })
            }
            results.push(set);
        }
    });
    return results;
}
