import {
    AfterViewInit,
    Component,
    EventEmitter, Inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {CommonModule} from '@angular/common';

import {Order, ORDER_TYPES, IOrderStage} from '@nxt/model-core';

import {OrderQuoteCount} from './order-quote-count';
import {ClientService} from '../../shared/_services/client.service';
import {IconsComponent} from '../../shared/icons/icons.component';
import {AvatarComponent} from '../../shared/avatars/avatar.component';
import {AvatarStackComponent} from '../../shared/avatars/avatar.stack.component';
import {takeUntil} from "rxjs/operators";
import {OnDestroyPage} from "../../shared/_inherited/ondestroy.page";

@Component({
    standalone: true,
    imports: [
        CommonModule, OrderQuoteCount, IconsComponent, AvatarComponent, AvatarStackComponent
    ],
    selector: 'nxt-order-item',
    template: `
        <ng-container #orderItem></ng-container>
        <ng-template #defaultTemplate>
        <div class="flex" (click)="onClick.emit(item)">
            <div class="p-3 flex flex-col place-items-center">
                <avatar-stack [items]="item?.agents" size="8"></avatar-stack>
                <div class="flex mt-2">
                    <icon name="heroicon-outline-globe-alt"
                            *ngIf="item.source===ORDER_TYPES.WEB"
                            class="text-gray-400 h-4 w-4 m-0.5"
                            title="Web Order"
                    ></icon>
                    <icon name="heroicon-outline-refresh"
                            *ngIf="item.source===ORDER_TYPES.SUBSCRIPTION"
                            class="text-gray-400 h-4 w-4 m-0.5"
                            title="Subscription Order"
                    ></icon>
                    <icon name="heroicon-outline-phone"
                            *ngIf="item.source===ORDER_TYPES.PHONE"
                            class="text-gray-400 h-4 w-4 m-0.5"
                            title="Phone Order"
                    ></icon>
                    <icon name="custom-bing"
                            *ngIf="item.metadata?.referrer?.search?.utm_source==='bing'"
                            class="text-gray-400 h-4 w-4 m-0.5"
                            title="Order via Bing"
                    ></icon>
                    <icon name="custom-google"
                            *ngIf="item.metadata?.referrer?.search?.utm_source==='google'||item.metadata?.referrer?.search?.gclid"
                            class="text-gray-400 h-4 w-4 m-0.5"
                            title="Order via Google"
                    ></icon>
                    <icon name="heroicon-solid-fire"
                            *ngIf="item.quote_id"
                            title="Order From Converted Quote"
                            class="text-gray-400 h-4 w-4 m-0.5"
                    ></icon>
                    <icon name="heroicon-outline-lock-closed"
                            *ngIf="item.locked"
                            class="text-gray-400 h-4 w-4 m-0.5"
                            title="Locked"
                    ></icon>
                    <icon name="heroicon-outline-archive"
                            *ngIf="item.metadata?.qb"
                            class="text-gray-400 h-4 w-4 m-0.5"
                            title="Order booked into accounting"
                    ></icon>
                    <icon name="heroicon-solid-x-circle"
                            *ngIf="item.canceled"
                            class="text-red-600 h-4 w-4 m-0.5"
                            title="Order Cancelled"
                    ></icon>
                    <icon name="heroicon-outline-refresh"
                            *ngIf="item.replacement"
                            class="text-blue-600 h-4 w-4 m-0.5"
                            title="Replacement Order"
                    ></icon>
                    <icon name="heroicon-outline-badge-check"
                            *ngIf="item.incorrect?.order_id"
                            class="text-orange-500 h-4 w-4 m-0.5"
                            title="Incorrect Order"
                    ></icon>
                    <icon name="heroicon-outline-badge-check"
                            *ngIf="item.corrected?.order_id"
                            class="text-gray-400 h-4 w-4 m-0.5"
                            title="Corrected Order"
                    ></icon>
                    <icon name="heroicon-outline-truck"
                            *ngIf="item.metadata?.ltl"
                            class="text-gray-400 h-4 w-4 m-0.5"
                            title="LTL Order"
                    ></icon>
                </div>
            </div>
            <div class="p-2 flex w-full justify-between">
                <div>
                    <div class="flex flex-col p-1 place-items-baseline">
                        <div class="flex place-items-center">
                            <order-quote-count [contact]="item.contact"></order-quote-count>
                            <div class="font-bold">{{ item.contact?.name }}</div>
                        </div>
                        <div class="italic text-xs pl-4" *ngIf="item.contact?.company">{{ item.contact?.company }}</div>
                        <div class="italic text-xs pl-4">{{ item.invoice_id }}</div>
                    </div>
                    <div class="flex flex-col">
                        <div class="flex pl-8">
                            <div class="p-1 flex flex-col place-items-center"
                                 *ngFor="let stage of stages"
                                 [style.color]="stage.color"
                            >
                                <icon [name]="stage.icon" [title]="stage.label" class="h-6 w-4"></icon>
                                <div class="w-full text-xs align-center hidden lg:visible">{{ stage.label }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="italic text-xs text-right">{{ item.date | date:'h:mm a' }}</div>
                    <div class="font-bold">{{ item.total | currency:'USD':'symbol'}}</div>
                </div>
            </div>
        </div>
        </ng-template>
    `
})
export class NxtOrderItemComponent extends OnDestroyPage implements OnChanges,AfterViewInit {
    @ViewChild('orderItem', {read: ViewContainerRef, static: false}) orderItem: ViewContainerRef;
    @ViewChild('defaultTemplate', {static: false}) defaultTemplate;
    @Output() onClick: EventEmitter<Order> = new EventEmitter<Order>();
    @Input() item: Order;
    ORDER_TYPES = ORDER_TYPES;
    stages: any[];
    itemComp:NxtOrderItemComponent

    constructor(
        protected cSvc: ClientService,
        @Inject('LazyComponents') protected lazyComponents: any[]
    ) {
        super()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.item) {
            this.stages = this.cSvc.client$.getValue()?.orderStages?.map((i:IOrderStage) => {
                return {
                    icon: i.icon,
                    label: i.label,
                    color: (this.item.stages && this.item.stages[i.type||i.id]) ? this.item.stages[i.type||i.id].color : '#dedede'
                }
            });
        }
    }

    async ngAfterViewInit() {
        if(this.defaultTemplate) {
            await this.loadComponent();
        }
    }

    async loadComponent() {
        const selector: string = `${this.cSvc.name_key.toLowerCase()}-order-item`;

        if (!this.itemComp || this.itemComp['_id'] !== selector) {
            let component: any;
            for (let c of this.lazyComponents) {
                for (let key of Object.keys(c)) {
                    if (key === selector) {
                        component = c[key];
                        break;
                    }
                }
            }

            if (!component?.loader) {
                this.loadDefaultTemplate();
                return;
            } else {
                try {
                    let imported = await component.loader();

                    const key = Object.keys(imported).find(key => !key.match(/Module/));
                    if (!key) {
                        console.warn(`No export found in module ${selector}.`);
                        this.loadDefaultTemplate();
                        return;
                    }
                    let module = imported[key];
                    let ref = this.orderItem.createComponent(module);
                    this.itemComp = ref.instance as NxtOrderItemComponent;
                    this.itemComp['_id'] = selector;
                    this.itemComp.item = this.item;
                    this.itemComp.onClick
                        .pipe(takeUntil(this.itemComp.d$))
                        .subscribe(e => {
                            this.onClick.emit(e);
                        });

                } catch (e) {
                    console.error(`Failed to load component ${selector}:`, e);
                    this.loadDefaultTemplate();
                }
            }
        } else {
            this.itemComp.item = this.item;
        }
    }

    loadDefaultTemplate() {
        if (this.defaultTemplate) {
            this.orderItem.createEmbeddedView(this.defaultTemplate);
        } else {
            console.error('defaultTemplate is undefined');
        }
    }


}
