"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Address = exports.ADDRESS_TYPES = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
var ADDRESS_TYPES;
(function (ADDRESS_TYPES) {
    ADDRESS_TYPES["billing"] = "billing";
    ADDRESS_TYPES["shipping"] = "shipping";
})(ADDRESS_TYPES || (exports.ADDRESS_TYPES = ADDRESS_TYPES = {}));
class Address extends Base_1.Base {
    set name(n) {
        this._name = n;
        if (n && !this.first_name) {
            let name_parts = n.split(/ /);
            switch (name_parts.length) {
                case 1:
                    this.first_name = n;
                    break;
                case 2:
                    this.first_name = name_parts[0];
                    this.last_name = name_parts[1];
                    break;
                default:
                    this.first_name = name_parts[0];
                    name_parts.splice(0, 1);
                    this.last_name = name_parts.join(' ');
                    break;
            }
        }
    }
    get name() {
        if (this.first_name) {
            this._name = `${(this.first_name) ? this.first_name : ''}${(this.last_name) ? ' ' + this.last_name : ''}`;
        }
        return this._name;
    }
    get errors() {
        var _a, _b, _c, _d, _e;
        return ((_e = (_d = (_c = (_b = (_a = this.metadata) === null || _a === void 0 ? void 0 : _a.easypost) === null || _b === void 0 ? void 0 : _b.verifications) === null || _c === void 0 ? void 0 : _c.delivery) === null || _d === void 0 ? void 0 : _d.errors) === null || _e === void 0 ? void 0 : _e.map(e => e.message)) || [];
    }
    constructor(data, olMap) {
        var _a;
        super('addresses', 14);
        this.user_id = '';
        this.first_name = '';
        this.last_name = '';
        this.nickname = '';
        this.default = null;
        this.company = '';
        this.street = '';
        this.street2 = '';
        this.city = '';
        this.state = '';
        this.zip = '';
        this.country = 'US';
        this.notes = '';
        this.flag = '';
        this.phone = '';
        this.email = '';
        this.residential = null;
        this.latitude = null;
        this.longitude = null;
        this.type = '';
        this.type_id = '';
        this.metadata = {};
        this.files = new index_1.ChildArray();
        (0, Common_1.setObjectProperties)(this, data, olMap, Address);
        this.active = (this.active === null) ? true : this.active;
        if (this.zip && !this.city && !this.street && !this.state) {
            this.active = false;
        }
        if (((_a = this.metadata) === null || _a === void 0 ? void 0 : _a.residential) !== undefined && this.residential === null) {
            this.residential = this.metadata.residential;
        }
    }
    toString() {
        return ['street', 'city', 'zip'].reduce((r, p) => {
            if (this[p]) {
                r.push(this[p]);
            }
            return r;
        }, []).join(', ');
    }
    toShortString(long) {
        let result = '';
        if (this.nickname && !long) {
            result = this.nickname;
        }
        else {
            result = this.street;
            if (this.street2) {
                result += ', ' + this.street2;
            }
            if (this.zip) {
                result += ', ' + this.zip;
            }
            else if (this.city) {
                result += ', ' + this.city;
            }
            if (this.state) {
                result += ', ' + this.state;
            }
        }
        return result;
    }
    toStripeJSON() {
        let r = { name: this.name, address_zip: this.zip || '' };
        if (this.email) {
            r.email = this.email;
        }
        if (this.phone) {
            r.phone = this.phone;
        }
        if (this.street && this.city && this.state) {
            r.address_city = this.city;
            r.address_line1 = this.street;
            r.address_line2 = this.street2 || '';
            r.address_state = this.state;
        }
        return r;
    }
    toJSON() {
        let r = super.toJSON();
        r.name = this.name || '';
        r.nickname = this.nickname || '';
        r.default = this.default;
        return r;
    }
    toMinJSON(ignoreDocRef) {
        let r = super.toMinJSON(ignoreDocRef);
        ['name', 'nickname', 'company', 'street', 'street2', 'city', 'state', 'zip', 'country', 'latitude', 'longitude'].forEach(p => {
            r[p] = this[p] || '';
        });
        return r;
    }
    validate(stateOnly) {
        if (!this.name && !this.company) {
            throw { title: 'Address Incomplete', message: 'Must include name or company in this.' };
        }
        else if (!this.zip && !this.street && !this.city && !this.state && !stateOnly) {
            throw { title: 'Address Incomplete', message: 'Must include zip or street, city, and state.' };
        }
        else if (!this.zip && (!this.street || !this.city || !this.state) && !stateOnly) {
            throw { title: 'Address Incomplete', message: 'Must include zip or street, city, and state.' };
        }
        else if (!this.phone && !this.email) {
            throw { title: 'Address Incomplete', message: 'Must include either an email or phone.' };
        }
        else if (this.phone && !(0, Common_1.isValidPhone)(this.phone)) {
            throw { title: 'Bad Phone Number?', message: 'Phone number entered does not appear to be valid.' };
        }
        else if (this.email && !(0, Common_1.isValidEmail)(this.email)) {
            throw { title: 'Bad Email?', message: 'Email entered does not appear to be valid.' };
        }
        else if (stateOnly && !this.state) {
            throw { title: 'Address Incomplete', message: 'Must include state.' };
        }
    }
    async toSearchJSON() {
        let r = await super.toSearchJSON();
        Object.assign(r, this.toMinJSON());
        r.default = this.default || false;
        if (this.latitude && this.longitude) {
            r._geoloc = {
                lat: Number(this.latitude),
                lng: Number(this.longitude)
            };
        }
        return r;
    }
}
exports.Address = Address;
index_1.olm.addresses = (ref, map) => {
    return new Address(ref, map);
};
