"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NxtoQuote = exports.NxtoOrder = exports.Quote = exports.Cart = exports.Order = exports.ORDER_TYPES = exports.EOrderStageColors = void 0;
const Common_1 = require("./Common");
const OrderItem_1 = require("./OrderItem");
const Discount_1 = require("./Discount");
const index_1 = require("../index");
const date_fns_tz_1 = require("date-fns-tz");
var EOrderStageColors;
(function (EOrderStageColors) {
    EOrderStageColors["NONE"] = "";
    EOrderStageColors["GREEN"] = "green";
    EOrderStageColors["ORANGE"] = "orange";
    EOrderStageColors["RED"] = "red";
    EOrderStageColors["BLACK"] = "black";
})(EOrderStageColors || (exports.EOrderStageColors = EOrderStageColors = {}));
var ORDER_TYPES;
(function (ORDER_TYPES) {
    ORDER_TYPES[ORDER_TYPES["WEB"] = 1] = "WEB";
    ORDER_TYPES[ORDER_TYPES["PHONE"] = 2] = "PHONE";
    ORDER_TYPES[ORDER_TYPES["SUBSCRIPTION"] = 3] = "SUBSCRIPTION";
})(ORDER_TYPES || (exports.ORDER_TYPES = ORDER_TYPES = {}));
class Order extends index_1.Base {
    get total_taxable() {
        return (0, Common_1.round)((this.subtotal || 0) + ((typeof this.shipping === 'object') ? 0 : Number(this.shipping || 0)) - ((this.discount) ? Number(this.discount.amount || 0) : 0), 2);
    }
    get balance() {
        return (0, Common_1.round)((this.total || 0) - (this.amount_refunded || 0) - (this.amount_paid || 0), 2);
    }
    get _payment_balance() {
        return (0, Common_1.round)((this.total || 0) - (this.amount_refunded || 0) - (this._payment_amounts || 0), 2);
    }
    get pieces() {
        let n = 0;
        this.items.forEach(i => n = n + (i.quantity || 0));
        return n;
    }
    constructor(data, olMap, type, idLen) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        super(type || 'orders', idLen || 26);
        this.contact_id = ''; // TODO deprecate
        this.invoice_id = '';
        this.invoice_index = null;
        this.user_id = ''; // TODO deprecate
        this.num_delivered = null;
        this.num_shipped = null;
        this.paid = false;
        this.date_paid = null;
        this.date_changed = null;
        this.amount_paid = 0;
        this.amount_refunded = 0;
        this.total = null;
        this.shipping = null;
        this.subtotal = null;
        this.total_weight = null;
        this.expires = null; // quote only?
        this.locked = null;
        this.canceled = null;
        this.pending = false;
        this.source = 0;
        this.referrer = {}; // fix or deprecate
        this.quote_id = '';
        this.replacement = null;
        // If incorrect is set, the values are the order/invoice id of the corrected version of this order.
        this.incorrect = null;
        // If corrected is set, the values are the order/invoice of the incorrect order being corrected by this one.
        this.corrected = null;
        this.url = '';
        this.contact = null;
        this.user = null; // This is for the public-facing side, which uses 'user' instead of 'contact'
        this.items = new index_1.ChildArray();
        this.payments = new index_1.ChildArray();
        this.shipments = new index_1.ChildArray();
        this.refunds = new index_1.ChildArray();
        this.work = [];
        this.files = new index_1.ChildArray();
        this.agents = [];
        this.workers = [];
        this.metadata = {};
        this.discount = { amount: 0, code: '' };
        this.stages = {};
        this.tax = { amount: 0, totalRate: 0, rates: [], total_taxable: 0, exempt: null };
        this.score = null;
        this.address = null;
        this.selected_rate = null;
        this.third_party = []; // For storing third-party info/ids.
        this._s = ['items', 'contact', 'invoice_id', 'quote_id', 'stages', 'address', 'total', 'tax', 'discount', 'source', 'locked', 'canceled', 'num_delivered', 'num_shipped', 'replacement'];
        (0, Common_1.setObjectProperties)(this, data, olMap, Order);
        // TODO Remove all this conversion stuff.
        if (this.metadata.replacement) {
            this.replacement = true;
            delete this.metadata.replacement;
        }
        if (this.metadata.corrected_order) {
            this.incorrect = this.metadata.corrected_order;
            delete this.metadata.corrected_order;
        }
        if (this.metadata.linked_order) {
            this.corrected = this.metadata.linked_order;
        }
        if (((_b = (_a = this.metadata) === null || _a === void 0 ? void 0 : _a.qb) === null || _b === void 0 ? void 0 : _b.QBID) || ((_c = this.metadata) === null || _c === void 0 ? void 0 : _c.QBID)) {
            this.setThirdPartyId('qb', ((_e = (_d = this.metadata) === null || _d === void 0 ? void 0 : _d.qb) === null || _e === void 0 ? void 0 : _e.QBID) || ((_f = this.metadata) === null || _f === void 0 ? void 0 : _f.QBID));
        }
        if ((_h = (_g = this.metadata) === null || _g === void 0 ? void 0 : _g.qb) === null || _h === void 0 ? void 0 : _h.QBCMID) {
            this.setThirdPartyId('qb-memo', this.metadata.qb.QBCMID);
        }
    }
    toString() {
        var _a;
        return `Order: ${this.invoice_id}: ${((_a = this.contact) === null || _a === void 0 ? void 0 : _a.name) || this['name'] || ''}`;
    }
    setItemsByQty(u) {
        this.items_by_qty = [];
        if (this.items && this.items.length) {
            let total = 1;
            this.items.forEach((item) => {
                let n = 1;
                while (n <= item.quantity) {
                    let piece = new OrderItem_1.OrderItem(JSON.parse(JSON.stringify(item)));
                    piece.quantity = 1;
                    piece.index = total;
                    piece.id = `${item.id}-${n}`;
                    piece.calc(u);
                    this.items_by_qty.push(piece);
                    total++;
                    n++;
                }
            });
        }
    }
    markPaid() {
        if (this.amount_paid < this.total) {
            throw {
                title: 'Cannot Mark Paid',
                message: `The amount paid is not at least as much as the order total.`
            };
        }
        this.paid = true;
    }
    setStage(name, value) {
        var _a;
        this.stages[name] = this.stages[name] || {};
        if (value.agent) {
            this.stages[name].agent = value.agent.toMinJSON ? (_a = value.agent) === null || _a === void 0 ? void 0 : _a.toMinJSON() : value.agent;
        }
        if (value.color) {
            this.stages[value.color] = Date.now();
        }
        this.stages[name].date = Date.now();
    }
    getStageColor(stage) {
        let r = EOrderStageColors.NONE;
        try {
            r = this.stages[stage].color;
        }
        catch (e) { }
        return r;
    }
    calc(u) {
        this.calcRefunds(u);
        this.calcSubtotal(u);
        this.calcShipping(u);
        this.calcPayments(u);
        this.calcDiscount(u);
        this.calcTax(u);
        this.calcTotals(u);
    }
    calcRefunds(u) {
        var _a, _b, _c, _d;
        if ((_a = this.refunds) === null || _a === void 0 ? void 0 : _a.length) {
            this.amount_refunded = (_b = this.refunds) === null || _b === void 0 ? void 0 : _b.reduce((n, refund) => {
                console.log(refund);
                n += Number(refund.amount ? refund.amount : 0);
                return n;
            }, 0);
            // Make sure refund amounts are always negative.
            if (this.amount_refunded > 0) {
                this.amount_refunded = this.amount_refunded * -1;
            }
        }
        if ((_c = this.payments) === null || _c === void 0 ? void 0 : _c.find(p => p.amount < 0)) {
            this.amount_refunded = (_d = this.payments) === null || _d === void 0 ? void 0 : _d.reduce((n, refund) => {
                if (refund.amount < 0) {
                    n += Number(refund.amount ? refund.amount : 0);
                }
                return n;
            }, 0);
        }
    }
    calcSubtotal(u) {
        var _a, _b;
        if ((_a = this.items) === null || _a === void 0 ? void 0 : _a.length) {
            this.total_weight = 0;
            this.subtotal = (0, Common_1.round)((_b = this.items) === null || _b === void 0 ? void 0 : _b.reduce((n, item) => {
                if (item.calc) {
                    item.calc(u);
                    if (item.total_weight) {
                        this.total_weight += item.total_weight;
                    }
                }
                n += item.subtotal;
                return n;
            }, 0), 2);
        }
    }
    calcShipping(u) {
        var _a;
        if ((_a = this.shipments) === null || _a === void 0 ? void 0 : _a.length) {
            this.shipping = (0, Common_1.round)(this.shipments.reduce((n, item) => {
                n += item.free ? 0 : item.amount;
                return n;
            }, 0), 2);
        }
    }
    calcPayments(u) {
        var _a;
        if ((_a = this.payments) === null || _a === void 0 ? void 0 : _a.length) {
            this.amount_paid = (0, Common_1.round)(this.payments.reduce((n, payment) => {
                if (Number(payment.amount) > 0 && payment.paid === true) {
                    n += Number(payment.amount);
                }
                return n;
            }, 0), 2);
            this._payment_amounts = (0, Common_1.round)(this.payments.reduce((n, payment) => {
                if (Number(payment.amount) > 0) {
                    n += Number(payment.amount);
                }
                return n;
            }, 0), 2);
        }
        else {
            this._payment_amounts = 0;
        }
    }
    calcTax(u) {
        var _a;
        if ((_a = this.tax) === null || _a === void 0 ? void 0 : _a.totalRate) {
            if (this.tax.exempt) {
                this.tax.amount = 0;
            }
            else {
                // console.log('total_taxable', this.total_taxable);
                this.tax.amount = (0, Common_1.round)((this.total_taxable || 0) * (this.tax.totalRate || 0), 2);
            }
        }
    }
    calcDiscount(u) {
        if (this.discount && this.discount.type) {
            this.discount = new Discount_1.Discount(this.discount);
            this.discount.calc(this);
            if (!this.discount.amount) {
                this.discount = null;
            }
        }
    }
    calcTotals(u) {
        var _a, _b;
        this.total = Number((0, Common_1.round)((this.subtotal || 0)
            - (((_a = this.discount) === null || _a === void 0 ? void 0 : _a.amount) || 0)
            + (this.shipping || 0)
            + (((_b = this.tax) === null || _b === void 0 ? void 0 : _b.amount) || 0), 2));
    }
    toJSON() {
        var _a;
        let r = super.toJSON();
        r.last_date = r.date;
        r.balance = this.balance || 0;
        r.total_taxable = this.total_taxable || 0;
        r.contact_id = ((_a = this.contact) === null || _a === void 0 ? void 0 : _a.id) || '';
        return r;
    }
    toMinJSON(ignoreDocRef) {
        let r = super.toMinJSON(ignoreDocRef);
        Object.assign(r, {
            name: this.toString(),
            type: '',
            total: this.total,
            id: this.id,
            invoice_id: this.invoice_id || '',
            quote_id: this.quote_id || ''
        });
        return r;
    }
    async clean(c, parent, keep, remove, keepDiscount) {
        await this.loadAll();
        let delProps = ['_docRef', 'id', 'incorrect', 'num_delivered', 'corrected', 'canceled', 'invoice_id', 'date', 'date_paid', 'pending', 'locked', 'paid', 'quote_id', 'source', 'stages', 'work', 'discount'];
        // When copying quotes to orders, we want to preserve the discount
        // But that must be specified when calling this method.
        if (keepDiscount) {
            delProps.splice(delProps.findIndex(p => p === 'discount'), 1);
        }
        (remove || []).concat(delProps).forEach(p => {
            delete c[p];
        });
        ['qb', 'supplier_id'].forEach((prop) => {
            delete c.metadata[prop];
        });
        c.pending = true;
        c.locked = false;
        c.canceled = null;
        c.paid = false;
        delete c._docRef;
        c.calc();
        if (parent) {
            await c.save(parent);
        }
        await Promise.all((keep || []).map(async (p) => {
            var _a;
            if (c[p] instanceof Array) {
                await Promise.all((_a = c[p]) === null || _a === void 0 ? void 0 : _a.map(async (i, n) => {
                    delete c[p][n]._docRef;
                    delete c[p][n].id;
                    if (parent && c[p][n].save) {
                        await c[p][n].save(c);
                    }
                }));
            }
        }));
        return c;
    }
    async copy(parent) {
        return new Order(await this.clean(new Order(this), parent, ['items'], ['shipments', 'payments']));
    }
    async save(parent) {
        var _a, _b;
        if (this._type === 'quotes') {
            this.quote_id = this.quote_id || (0, Common_1.getInvoiceOrQuoteID)(true, (0, date_fns_tz_1.utcToZonedTime)(new Date(), parent ? parent['timezone'] || 'America/Chicago' : 'America/Chicago'));
        }
        else if (this._type === 'orders') {
            this.invoice_id = this.invoice_id || (0, Common_1.getInvoiceOrQuoteID)(false, (0, date_fns_tz_1.utcToZonedTime)(new Date(), parent ? parent['timezone'] || 'America/Chicago' : 'America/Chicago'));
        }
        if (((_a = this.address) === null || _a === void 0 ? void 0 : _a.id) && !this.address._docRef && (parent === null || parent === void 0 ? void 0 : parent._docRef) && ((_b = this.contact) === null || _b === void 0 ? void 0 : _b.id)) {
            this.address._docRef = parent._docRef.collection('contacts').doc(this.contact.id).collection('addresses').doc(this.address.id);
        }
        await super.save(parent);
    }
}
exports.Order = Order;
index_1.olm.orders = (ref, map) => {
    return new Order(ref, map);
};
class Cart extends Order {
    constructor(data, olMap) {
        super(data, olMap);
        (0, Common_1.setObjectProperties)(this, data, olMap || index_1.olm, Cart);
    }
    async copyToOrder(parent, keep) {
        await this.loadAll();
        return new Order(await super.clean(new Order(this), parent, keep || ['items', 'shipments'], null, true));
    }
}
exports.Cart = Cart;
class Quote extends Order {
    constructor(data, olMap) {
        super(data, olMap, 'quotes', 26);
        (0, Common_1.setObjectProperties)(this, data, olMap, Quote);
    }
    async copy(parent) {
        return new Quote(await super.clean(new Quote(this), parent, ['items', 'shipments'], null, true));
    }
    async copyToOrder(parent, keep) {
        await this.loadAll();
        return new Order(await super.clean(new Order(this), parent, keep || ['items', 'shipments'], null, true));
    }
}
exports.Quote = Quote;
index_1.olm.quotes = (ref, map) => {
    return new Quote(ref, map);
};
class NxtoOrder extends Order {
    constructor(data, olMap, type, idLen) {
        super(data, olMap, type || 'orders', idLen || 31);
        this.client = null;
        (0, Common_1.setObjectProperties)(this, data, olMap, NxtoOrder);
    }
    validate() {
        var _a, _b, _c;
        console.log(this.metadata.type);
        if (this.getStageColor('items') !== EOrderStageColors.GREEN) {
            return {
                title: 'Cannot Move Forward',
                message: 'The Items section must be marked as done.',
                path: 'items',
                color: EOrderStageColors.ORANGE
            };
        }
        else {
            switch (this.metadata.type) {
                case 'new':
                case 'existing':
                    if (!((_a = this.client) === null || _a === void 0 ? void 0 : _a.name_key)) {
                        return {
                            title: 'Cannot Move Forward',
                            message: 'The Client section must at least have the Name Key property set.',
                            path: 'client',
                            color: EOrderStageColors.ORANGE
                        };
                    }
                    break;
                case 'group':
                    console.log(this.metadata);
                    if (!((_b = this.metadata) === null || _b === void 0 ? void 0 : _b.access_code) || !((_c = this.metadata) === null || _c === void 0 ? void 0 : _c.member_max)) {
                        return {
                            title: 'Cannot Move Forward',
                            message: `The group's access code and max limit must be set.`,
                            path: 'client',
                            color: EOrderStageColors.ORANGE
                        };
                    }
                    break;
            }
        }
    }
    markPaid() {
        let err = this.validate();
        if (err) {
            throw err;
        }
        else {
            super.markPaid();
        }
    }
    toJSON() {
        var _a;
        let r = super.toJSON();
        r.client = ((_a = this.client) === null || _a === void 0 ? void 0 : _a.toJSON) ? this.client.toJSON() : this.client;
        return r;
    }
}
exports.NxtoOrder = NxtoOrder;
class NxtoQuote extends NxtoOrder {
    constructor(data, olMap) {
        super(data, olMap, 'quotes', 31);
        (0, Common_1.setObjectProperties)(this, data, olMap, NxtoQuote);
    }
}
exports.NxtoQuote = NxtoQuote;
