import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {PageService} from '@library/shared/_services/page.service';
import {FormControl, FormsModule, ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {InputDateCalendar} from './input-date-calendar';
import {format, parse, startOfDay} from 'date-fns';
import {takeUntil} from 'rxjs/operators';
import {OnDestroyPage} from '../_inherited/ondestroy.page';

@Component({
    selector: 'input-date',
    standalone: true,
    imports: [
        CommonModule,
        IconsComponent,
        FormsModule, ReactiveFormsModule,
        IconsComponent, InputDateCalendar
    ],
    template: `
        <div [formGroup]="form" [class]="class ? class : 'w-full bg-white text-left rounded-2xl text-gray-500'">
            <div class="p-1">
                <div class="flex ml-3 left-0 bottom-0">
                    <icon name="heroicon-outline-calendar" class="h-5 w-5 mr-1"></icon>
                    <label [for]="controlName">{{ label }}</label>
                </div>
                <input type="text"
                       minlength="10"
                       maxlength="10"
                       [placeholder]="placeholder"
                       (focus)="show=true"
                       [formControlName]="controlName"
                       [id]="controlName"
                       class="w-full mt-0 text-gray-500 rounded-b-2xl text-2xl border-0 bg-transparent {{inputClass}}"
                />
                <p *ngIf="form?.get(controlName).touched && form.get(controlName)?.hasError('required')"
                   class="px-3 pt-1 italic text-xs text-red-500">
                    Required
                </p>
                <p *ngIf="form?.get(controlName)?.touched && form.get(controlName)?.hasError('invalid')"
                   class="px-3 pt-1 italic text-xs text-red-500">
                    Required format: mm/dd/yyyy
                </p>
                <p *ngIf="form?.get(controlName)?.touched && form.get(controlName)?.hasError('min')"
                   class="px-3 pt-1 italic text-xs text-red-500">
                    Date not allowed
                </p>
            </div>
        </div>
        <div *ngIf="show" class="absolute right-0 z-[30] mt-0 flex w-screen max-w-max px-4">
            <div class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                <div class="bg-gray-100 p-4">
                    <input-date-calendar
                            [minDate]="minDate" 
                            [date]="form.get(controlName).value"
                            (onClose)="setDate($event)"
                    ></input-date-calendar>
                </div>
            </div>
        </div>
    `
})
export class InputDateComponent extends OnDestroyPage implements OnChanges {
    @Output() onChange: EventEmitter<Date> = new EventEmitter<Date>();
    @Input() inputClass: string;
    @Input() class: string = '';
    @Input() label: string;
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;
    @Input() value: Date;
    @Input() minDate: Date;
    @Input() placeholder: string = 'mm/dd/yyyy';
    @Input() zIndex: string = ''
    placement: 'top-right'|'top-left' = 'top-right';
    show: boolean;
    added: boolean;

    constructor(
        public pSvc: PageService,
        private eRef: ElementRef
    ) {

        super();
        this.pSvc.click$
            .pipe(takeUntil(this.d$))
            .subscribe(
                e => {
                    if (e
                        && e.target
                        && this.eRef?.nativeElement
                        && !this.eRef.nativeElement.contains(e.target)
                    ) {
                        this.show = false;
                    }
                }
            );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.minDate) {
            this.minDate = startOfDay(this.minDate);
        }
        if (this.form && this.controlName && !this.added) {
            this.added = true;
            this.form.get(this.controlName).addValidators([this.dateValidator(this)]);
        }
    }

    dateValidator(t) {
        return (control: FormControl) => {
            if (control.value) {
                let d: Date = parse(control.value, 'M/d/yyyy', new Date());
                if (isNaN(d.valueOf())) {
                    return { invalid: true };
                } else if (this.minDate && this.minDate.valueOf() > d.valueOf()) {
                    return {min: true};
                } else {
                    this.value = d;
                }
            }
            return null;
        }
    }

    setDate(d: Date) {
        this.show = false;
        if (d) {
            this.value = d;
            this.form.get(this.controlName).setValue(format(d, 'MM/dd/yyyy'));
            this.onChange.emit(d);
        }
    }


}
