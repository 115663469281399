export let gmtlLazyComponents: any = {
   'blog-featured-article-component': {
      t: 'Blog: Featured Article',
      client: 'GMTL',
      loader: () => import('@library/shared/blog/blog-featured-article'),
   },
   'blog-content-block': {
      t: 'Block Content Block',
      client: 'GMTL',
      loader: () => import('@library/shared/blog/blog-content-block'),
      config: () => import('@library/shared/blog/blog-content-block-config')
   },
   'gmtl-dark-header': {
      t: 'Getmetals Dark Header',
      client: 'GMTL',
      loader: () => import('./header/gmtl-dark-header'),
   },
   'hero-home-component': {
      t: 'Hero Home Banner',
      client: 'GMTL',
      loader: () => import('./hero-home/hero-home'),
      config: () => import('./hero-home/hero-home-config')
   },
   'customer-reviews-component': {
      t: 'Customer Reviews',
      client: 'GMTL',
      loader: () => import('./customer-reviews/customer-reviews'),
      config: () => import('./customer-reviews/customer-reviews-config')
    },
   'product-grid-component': {
      t: 'Product Grid',
      client: 'GMTL',
      loader: () => import('./product-grid/product-grid'),
      config: () => import('./product-grid/product-grid-config')
   },
   'contact-us-component': {
      t: 'Contact Us',
      client: 'GMTL',
      loader: () => import('./contact-us/contact-us'),
      config: () => import('./contact-us/contact-us-config')
   },
   'right-nav-component': {
      t: 'Right Nav',
      client: 'GMTL',
      loader: () => import('./right-nav/right-nav')
   },
   'shape-picker-component': {
      t: 'Shape Picker',
      client: 'GMTL',
      loader: () => import('./shape-picker/shape-picker'),
      config: () => import('./shape-picker/shape-picker-config')
   },
   'instagram-component': {
      t: 'Instagram',
      client: 'GMTL',
      loader: () => import('./instagram/instagram')
   },
   'featured-footer-component': {
      t: 'Featured Footer',
      type: 'footer',
      client: 'GMTL',
      loader: () => import('./featured-footer/featured-footer')
   },

   'grayson-one-component': {
      t: '"Grayson" One',
      client: 'GMTL',
      loader: () => import('./grayson-one/grayson-one')
   },
   'product-picker-new': {
      t: 'Product Picker for New NXT',
      client: 'GMTL',
      loader: () => import('./product-picker-new/product-picker-new'),
      config: () => import('./product-picker-new/product-picker-new-config')
   },
   'gmtl-nxt-order-details': {
      t: 'Order details component used in NXT only.',
      client: 'NXT',
      loader: () => import('../../../../nxto/src/app/_clients/gmtl/_components/gmtl-order-details.component')
   },
   'gmtl-nxt-product-details': {
      t: 'Product details editor component used in NXT only.',
      client: 'NXT',
      loader: () => import('../../../../nxto/src/app/_clients/gmtl/_components/gmtl-product-details.component')
   },
   'gmtl-nxt-pricing-details': {
      t: 'Pricing details editor component used in NXT only.',
      client: 'NXT',
      loader: () => import('../../../../nxto/src/app/_clients/gmtl/_components/gmtl-pricing-details.component')
   }
};
