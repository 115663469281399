import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    addDays,
    addMonths,
    endOfMonth,
    format,
    parse,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subMonths
} from 'date-fns';
import endOfWeek from 'date-fns/endOfWeek';
import {OnDestroyPage} from '_library/shared/_inherited/ondestroy.page';
import {takeUntil} from 'rxjs/operators';
import {PageService} from '_library/shared/_services/page.service';
import {IconsComponent} from '_library/shared/icons/icons.component';

@Component({
    selector: 'input-date-calendar',
    standalone: true,
    imports: [
        CommonModule,
        IconsComponent
    ],
    template: `
        <div>
            <div class="flex items-center justify-between p-1">
                <div>
                    <button class="btn-clear btn-sm" (click)="prevMonth()">
                        <span class="sr-only">Previous month</span>
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                        </svg>
                    </button>
                    <button class="btn-clear btn-sm" (click)="nextMonth()">
                        <span class="sr-only">Next month</span>
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                        </svg>
                    </button>    
                </div>
                <h2 class="flex-auto text-lg md:text-xl text-sm font-semibold text-gray-900 text-center">{{label}}</h2>
                <button class="btn-clear btn-sm" (click)="onClose.emit()">
                    <span class="sr-only">Close</span>
                    <icon name="heroicon-outline-x" class="h-5 w-5 text-gray-300 ml-4"></icon>
                </button>
            </div>
            <div class="mt-10 grid grid-cols-7 bg-white text-center text-xs leading-6 text-gray-500">
                <div>S</div>
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
            </div>
            <div class="mt-2 grid grid-cols-7 text-sm bg-white">
                <!--
                      Always include: "mx-auto flex h-8 w-8 items-center justify-center rounded-full"
                      Is selected, include: "text-white"
                      Is not selected and is today, include: "text-dark-600"
                      Is not selected and is not today and is current month, include: "text-gray-900"
                      Is not selected and is not today and is not current month, include: "text-gray-400"
                      Is selected and is today, include: "bg-dark-600"
                      Is selected and is not today, include: "bg-gray-900"
                      Is not selected, include: "hover:bg-gray-200"
                      Is selected or is today, include: "font-semibold"
                    -->
                <div class="py-2" *ngFor="let item of days; let i = index;">
                    <button (click)="setDate(item.date,i)" 
                            type="button" 
                            class="mx-auto flex h-8 w-8 items-center justify-center rounded-full hover:bg-gray-200
                                {{ item.past ? 'bg-gray-200 text-gray-400' 
                                    : item.selected ? 'bg-gray-900 font-semibold text-white'
                                    : item.today ? 'font-semibold text-dark-400'
                                    : item.month ? 'text-gray-800' : 'text-gray-400' }}">
                        <time>{{ item.label }}</time>
                    </button>
                </div>
            </div>
        </div>
    `
})
export class InputDateCalendar extends OnDestroyPage implements OnChanges {
    @Output() onClose: EventEmitter<Date> = new EventEmitter<Date>();
    @Input() date: string;
    @Input() minDate: Date;
    label: string;
    reference: Date;
    selected: Date;
    days: any[];

    constructor(
        private pSvc: PageService,
        private eRef: ElementRef
    ) {
        super();
    }

    ngOnChanges(changes?: SimpleChanges) {
        if (this.date && (!this.days?.length || changes.date)) {
            // The reference date is the date around which the calendar
            // display is built.  This begins with the 'date' value passed
            // into the component, but changes as the user navigates through
            // the calendar.
            this.reference = parse(this.date, 'MM/dd/yyyy', new Date());
            this.selected = parse(this.date, 'MM/dd/yyyy', new Date());
            if (this.minDate) {
                this.minDate = startOfDay(this.minDate);
            }
            this.buildDays();

            this.pSvc.clickEsc$
                .pipe(takeUntil(this.d$))
                .subscribe(e => {
                    this.onClose.emit();
                });
        }
    }

    setDate(d: Date, i: number) {
        if (!this.minDate || d.valueOf() >= this.minDate.valueOf()) {
            this.selected = d;
            this.reference = d;
            this.buildDays();
            this.onClose.emit(this.selected);
        }
    }

    buildDays() {
        this.label = format(this.reference, 'MMMM yyyy');
        // Build the list of days for the currently referenced month.
        let today: Date = new Date(Date.now());
        let start: Date = startOfWeek(startOfMonth(this.reference));
        let end: Date = endOfWeek(endOfMonth(this.reference));
        // If the start of the month is not a Monday, go backward until we find a monday.
        this.days = [];
        while (start <= end) {

            let item: any = {
                date: start,
                label: start.getDate(),
                past: (this.minDate && start.valueOf() < this.minDate.valueOf()),
                today: (start.getDate() === today.getDate() && start.getMonth() === today?.getMonth()),
                selected: (start.getDate() === this.selected?.getDate() && start.getMonth() === this.selected?.getMonth()),
                month: (start.getMonth() === this.reference.getMonth())
            };
            this.days.push(item);
            start = addDays(start, 1);
        }
    }

    prevMonth() {
        this.reference = subMonths(this.reference,1);
        this.buildDays();
    }

    nextMonth() {
        this.reference = addMonths(this.reference,1);
        this.buildDays();
    }
}
