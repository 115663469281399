"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = exports.RCRT_USER_TYPE_MAP = exports.RCRT_USER_TYPE = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
var RCRT_USER_TYPE;
(function (RCRT_USER_TYPE) {
    RCRT_USER_TYPE["NONE"] = "";
    RCRT_USER_TYPE["PLAYER"] = "p";
    RCRT_USER_TYPE["PLAYER_COACH"] = "pc";
    RCRT_USER_TYPE["COLLEGE_COACH"] = "cc";
    RCRT_USER_TYPE["PARENT"] = "pp";
    RCRT_USER_TYPE["ADVISOR"] = "a";
})(RCRT_USER_TYPE || (exports.RCRT_USER_TYPE = RCRT_USER_TYPE = {}));
exports.RCRT_USER_TYPE_MAP = {
    p: 'Player',
    pc: 'HS/Club Coach/Admin',
    cc: 'College Coach',
    pp: 'Parent',
    a: 'Advisor'
};
class User extends model_core_1.User {
    get results() {
        return {
            sent: this.results_sent,
            views: this.results_views,
            replies: this.results_replies,
            comments: this.results_comments,
            emails: this.results_emails,
            shares: this.results_shares
        };
    }
    constructor(data, olMap, type, idLen) {
        var _a, _b;
        super(data, olMap || index_1.olm);
        this.image = null;
        this.type = RCRT_USER_TYPE.NONE;
        this.bio = {};
        this.address = null;
        this.teams = [];
        this.links = {};
        this.collab = '';
        this.club = '';
        this.rating_factors = [];
        this.event_refs = new model_core_1.ChildArray();
        this.college_refs = new model_core_1.ChildArray();
        this.collabs = new model_core_1.ChildArray();
        this.shares = new model_core_1.ChildArray();
        this.last_check = 0;
        this.results_sent = 0;
        this.results_views = 0;
        this.results_replies = 0;
        this.results_comments = 0;
        this.results_emails = 0;
        this.results_shares = 0;
        this._m = ['name', 'email', 'first_name', 'last_name', 'birth_year', 'ncaa_id', 'grad_year', 'gender', 'image'];
        this._s = ['name', 'email', 'first_name', 'last_name', 'birth_year', 'ncaa_id', 'grad_year', 'gender', 'teams', 'image'];
        this._d = ['addresses', 'cards', 'devices', 'location', 'location_id', 'nick_name', 'online', 'photoURL', 'role', 'signature', 'tax', 'unread'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, User);
        if ((_a = this.teams) === null || _a === void 0 ? void 0 : _a.length) {
            this.teams = (_b = this.teams) === null || _b === void 0 ? void 0 : _b.map(t => {
                return t;
            });
        }
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
    }
    toJSON() {
        var _a, _b;
        let r = super.toJSON();
        ['x', 'instagram'].forEach(p => {
            if (this.links[p]) {
                let parts = this.links[p].split('/');
                if (parts.length) {
                    this.links[p] = parts[parts.length - 1];
                }
                this.links[p] = this.links[p].replace('@', '');
            }
        });
        if ((_a = this.teams) === null || _a === void 0 ? void 0 : _a.length) {
            r.teams = (_b = this.teams) === null || _b === void 0 ? void 0 : _b.map(t => {
                return t;
            });
        }
        return r;
    }
    toPublicJSON() {
        let r = this.toJSON();
        ['college_refs', 'event_refs'].forEach(p => {
            delete r[p];
        });
        return r;
    }
}
exports.User = User;
index_1.olm.users = (ref, map) => {
    return new User(ref, map);
};
