"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderItem = void 0;
const Dimension_1 = require("./Dimension");
const Common_1 = require("./Common");
const index_1 = require("../index");
class OrderItem extends index_1.Base {
    get total_weight() {
        if (this._override_weight) {
            return this._override_weight;
        }
        else if (this.unit_weight && this.quantity && !isNaN(this.unit_weight * this.quantity)) {
            return (0, Common_1.round)(this.unit_weight * this.quantity, (this.unit_weight * this.quantity) < 1 ? 4 : 2);
        }
    }
    constructor(data, olMap, type, idLen) {
        super(type || 'items', idLen || 11);
        this.index = null;
        this.sku = '';
        this.name = '';
        this.notes = '';
        this.url = '';
        this.unit_cost = null;
        this.unit_weight = null;
        this.quantity = 0;
        this.price = null;
        this.subtotal = null;
        this.dimensions = new Dimension_1.DimensionArray();
        this.packing_dimensions = new Dimension_1.DimensionArray();
        this.properties = {};
        this.metadata = {};
        this.pricing_id = '';
        this.pricing = null;
        this.product = null;
        this.subscription = false;
        this.stripe_price_id = '';
        this.subscription_type = index_1.ESubscriptionTypes.NONE;
        this.stripe_subscriptions = new index_1.ChildArray();
        this._override_weight = null;
        this._m = ['name', 'price', 'quantity', 'subtotal', 'product'];
        data = (0, Common_1.setObjectProperties)(this, data, olMap, OrderItem);
    }
    calc(u) {
        if (this.quantity) {
            if (this.price) {
                this.subtotal = Number((0, Common_1.round)(this.quantity * ((isNaN(this.price) === false) ? Number(this.price) : 0), 2));
            }
        }
    }
    async loadAll(opts, stack, p) {
        opts = opts || { olm: index_1.olm };
        // We must always make sure we have the latest copy of the product from the db, so look it up.
        if (this._docRef && this.sku && !this.product) {
            let q = await this._docRef.parent.parent.parent.parent.collection('products').where('sku', '==', this.sku).get();
            if (q.size) {
                this.product = (0, index_1.loadObject)(q.docs[0], opts);
            }
        }
        return super.loadAll(opts, stack, p);
    }
    toJSON() {
        // Make sure to set weights properly, which is done in the calc() method.
        this.calc();
        return super.toJSON();
    }
}
exports.OrderItem = OrderItem;
index_1.olm.items = (ref, map) => {
    return new OrderItem(ref, map);
};
